import { lazy } from 'react';
import { urlBuilder } from 'utils';

const Projects = lazy(() => import('pages/Projects/Projects'));
const ProjectDetails = lazy(() => import('pages/Projects/ProjectDetails'));

const AllProjects = lazy(() => import('pages/Projects/AllProjects'));
const OngoingAndCompletedProjects = lazy(() =>
  import('pages/Projects/OngoingAndCompletedProjects'),
);

// Supply Request
const SupplyRequestsDetails = lazy(() =>
  import('pages/SupplyRequest/SupplyRequestsDetails'),
);

const SupplyRequestHistory = lazy(() =>
  import('pages/SupplyRequest/SupplyRequestHistory'),
);

// Expense Request
const ExpenseRequestDetails = lazy(() =>
  import('pages/ExpenseRequest/ExpenseRequestDetails'),
);

const ExpenseRequestHistory = lazy(() =>
  import('pages/ExpenseRequest/ExpenseRequestHistory'),
);

// Purchase Order
const PurchaseOrderDetails = lazy(() =>
  import('pages/PurchaseOrder/PurchaseOrderDetails'),
);

const PurchaseOrderHistory = lazy(() =>
  import('pages/PurchaseOrder/PurchaseOrderHistory'),
);

export const PROJECT_ROUTES_PATHS = {
  DASHBOARD_PAGE: () => '/projects',
  BY_STATUS_PAGE: ({ isCompleted, isOngoing } = {}) => {
    let url = '/projects/by-status';

    if (isCompleted) {
      url += '?tab=completed';
    } else if (isOngoing) {
      url += '?tab=ongoing';
    }

    return url;
  },
  DETAILS_PAGE: ({
    id,
    isExpenseRequests,
    isSupplyRequests,
    isPurchaseOrders,
    isInventory,
    isIncomingInventory,
    isOutgoingInventory,
    isTransaction,
  } = {}) => {
    let url = urlBuilder(['project', id ? id : ':id', 'details']);

    if (isExpenseRequests) {
      url += '?tab=expense-requests';
    } else if (isSupplyRequests) {
      url += '?tab=supply-requests';
    } else if (isPurchaseOrders) {
      url += '?tab=purchase-orders';
    } else if (isInventory) {
      url += '?tab=inventory';
    } else if (isIncomingInventory) {
      url += '?tab=incoming-inventory';
    } else if (isOutgoingInventory) {
      url += '?tab=outgoing-inventory';
    } else if (isTransaction) {
      url += '?tab=transactions';
    }

    return url;
  },
  SUPPLY_REQUEST_DETAILS_PAGE: ({ id, projectId } = {}) =>
    urlBuilder([
      'project',
      projectId ? projectId : ':projectId',
      'supply-request',
      id ? id : ':id',
      'details',
    ]),
  SUPPLY_REQUEST_HISTORY_DETAILS_PAGE: ({ id, projectId, requestId } = {}) =>
    urlBuilder([
      'projects',
      projectId ? projectId : ':projectId',
      'supply-request',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
  EXPENSE_REQUEST_DETAILS_PAGE: ({ id, projectId } = {}) =>
    urlBuilder([
      'project',
      projectId ? projectId : ':projectId',
      'expense-request',
      id ? id : ':id',
      'details',
    ]),
  EXPENSE_REQUEST_HISTORY_DETAILS_PAGE: ({ id, projectId, requestId } = {}) =>
    urlBuilder([
      'projects',
      projectId ? projectId : ':projectId',
      'expense-request',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
  PURCHASE_ORDER_DETAILS_PAGE: ({ id, projectId } = {}) =>
    urlBuilder([
      'project',
      projectId ? projectId : ':projectId',
      'purchase-order',
      id ? id : ':id',
      'details',
    ]),
  PURCHASE_ORDER_HISTORY_DETAILS_PAGE: ({ id, projectId, requestId } = {}) =>
    urlBuilder([
      'projects',
      projectId ? projectId : ':projectId',
      'purchase-order',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
};

const projects = 'Projects';
const project = 'Project';

const ProjectRoutes = () => [
  {
    path: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
    element: <AllProjects />,
    index: true,
    protected: true,
  },
  // {
  //   path: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
  //   element: <Projects />,
  //   index: true,
  //   protected: true,
  // },
  {
    path: PROJECT_ROUTES_PATHS.DETAILS_PAGE(),
    element: <ProjectDetails />,
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.BY_STATUS_PAGE(),
    element: <OngoingAndCompletedProjects />,
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.SUPPLY_REQUEST_DETAILS_PAGE(),
    element: (
      <SupplyRequestsDetails
        breadCrumb={({ projectId, projectCode, requestCode }) => [
          {
            title: projects,
            url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
          },
          {
            title: projectCode || project,
            url: PROJECT_ROUTES_PATHS.DETAILS_PAGE({
              id: projectId,
              isSupplyRequests: true,
            }),
          },
          {
            title: requestCode || 'Supply request detail',
          },
        ]}
        editable={true}
        historyPageurlFunc={({ id, projectId, requestId }) =>
          PROJECT_ROUTES_PATHS.SUPPLY_REQUEST_HISTORY_DETAILS_PAGE({
            id,
            projectId,
            requestId,
          })
        }
      />
    ),
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.SUPPLY_REQUEST_HISTORY_DETAILS_PAGE(),
    element: (
      <SupplyRequestHistory
        breadCrumb={({ requestCode, projectCode, requestId, projectId }) => {
          const breadCrumb = [
            {
              title: projects,
              url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
            },
            {
              title: projectCode || project,
              url: PROJECT_ROUTES_PATHS.DETAILS_PAGE({
                id: projectId,
                isSupplyRequests: true,
              }),
            },
            {
              title: 'Supply request history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(2, 0, {
              title: requestCode,
              url: PROJECT_ROUTES_PATHS.SUPPLY_REQUEST_DETAILS_PAGE({
                id: requestId,
                projectId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.EXPENSE_REQUEST_DETAILS_PAGE(),
    element: (
      <ExpenseRequestDetails
        breadCrumb={({ projectId, projectCode, requestCode }) => [
          {
            title: projects,
            url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
          },
          {
            title: projectCode || project,
            url: PROJECT_ROUTES_PATHS.DETAILS_PAGE({
              id: projectId,
              isExpenseRequests: true,
            }),
          },
          {
            title: requestCode || 'Expense request detail',
          },
        ]}
        editable={true}
        historyPageurlFunc={({ id, projectId, requestId }) =>
          PROJECT_ROUTES_PATHS.EXPENSE_REQUEST_HISTORY_DETAILS_PAGE({
            id,
            projectId,
            requestId,
          })
        }
      />
    ),
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.EXPENSE_REQUEST_HISTORY_DETAILS_PAGE(),
    element: (
      <ExpenseRequestHistory
        breadCrumb={({ requestCode, requestId, projectId, projectCode }) => {
          const breadCrumb = [
            {
              title: projects,
              url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
            },
            {
              title: projectCode || project,
              url: PROJECT_ROUTES_PATHS.DETAILS_PAGE({
                id: projectId,
                isExpenseRequests: true,
              }),
            },
            {
              title: 'Expense request history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(2, 0, {
              title: requestCode,
              url: PROJECT_ROUTES_PATHS.EXPENSE_REQUEST_DETAILS_PAGE({
                id: requestId,
                projectId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.PURCHASE_ORDER_DETAILS_PAGE(),
    element: (
      <PurchaseOrderDetails
        breadCrumb={({ projectId, projectCode, requestCode }) => [
          {
            title: projects,
            url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
          },
          {
            title: projectCode || project,
            url: PROJECT_ROUTES_PATHS.DETAILS_PAGE({
              id: projectId,
              isPurchaseOrders: true,
            }),
          },
          {
            title: requestCode || 'Purchase order detail',
          },
        ]}
        editable={true}
        historyPageurlFunc={({ id, projectId, requestId }) =>
          PROJECT_ROUTES_PATHS.PURCHASE_ORDER_HISTORY_DETAILS_PAGE({
            id,
            projectId,
            requestId,
          })
        }
      />
    ),
    protected: true,
  },
  {
    path: PROJECT_ROUTES_PATHS.PURCHASE_ORDER_HISTORY_DETAILS_PAGE(),
    element: (
      <PurchaseOrderHistory
        breadCrumb={({ requestCode, requestId, projectId, projectCode }) => {
          const breadCrumb = [
            {
              title: projects,
              url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
            },
            {
              title: projectCode || project,
              url: PROJECT_ROUTES_PATHS.DETAILS_PAGE({
                id: projectId,
                isPurchaseOrders: true,
              }),
            },
            {
              title: 'Purchase order history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(2, 0, {
              title: requestCode,
              url: PROJECT_ROUTES_PATHS.PURCHASE_ORDER_DETAILS_PAGE({
                id: requestId,
                projectId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
];

export default ProjectRoutes;
