import { ShadowBoxContainer } from './ShadowBox.style';

// Valid text colors are red and orange. Default is grey

const ShadowBox = ({ topText, topTextColor, bottomText, bottomTextColor }) => {
  return (
    <ShadowBoxContainer>
      <div className={`top-text ${topTextColor}`}>{topText}</div>
      <div className={`bottom-text ${bottomTextColor}`}>{bottomText}</div>
    </ShadowBoxContainer>
  );
};

export default ShadowBox;
