import { lazy } from 'react';

const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));

export const DASHBOARD_ROUTES_PATHS = {
  DASHBOARD_PAGE: () => '/',
};

const DashboardRoutes = () => [
  {
    path: DASHBOARD_ROUTES_PATHS.DASHBOARD_PAGE(),
    element: <Dashboard />,
    index: true,
    protected: true,
  },
];

export default DashboardRoutes;
