import styled from 'styled-components';
import { device } from 'constants';

export const searchbarHeight = '2.8571rem';

export const Wrapper = styled.nav.attrs(() => ({
  className: 'top-nav',
}))`
  display: flex;
  align-items: center;
  padding: 1.1428rem 2.1428rem; //Check mainPage before changing this value
  justify-content: space-between;
  box-shadow: 0 0.2142rem 0.2857rem 0 rgba(203, 207, 211, 0.15);

  & > .nav-content {
    display: flex;
    min-height: ${searchbarHeight};
    justify-content: space-between;
    align-items: center;

    .searchbar {
      box-shadow: 0px 2px 4px 0px rgba(84, 84, 84, 0.05);
    }

    .divider {
      width: 1px;
      margin: 0 1.7142rem;
      height: ${searchbarHeight};
      border-left: 1px solid #cbcfd3;
    }
  }

  //   @media ${device.laptop} {
  //     width: calc(100vw - 13.8rem);
  //   }

  //   @media ${device.isSmallDevice} {
  //     width: 100%;
  //     padding-inline: 0.65rem;
  //     gap: 0.5rem;
  //   }

  //   .burger {
  //     margin-right: 0.65rem;

  //     rect {
  //       fill: ${props => props.theme.primaryColor};
  //     }
  //   }
`;

export const Avatar = styled.span`
  position: relative;

  img {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 520px) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;

export const User = styled.div`
  display: flex;
  gap: 0.5rem;

  .user-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
    font-family: Roboto;

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 10rem;
    }

    .name {
      color: #1a1a1a;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.1rem;
    }

    .role {
      color: #1a1a1a;
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @media (max-width: 520px) {
      display: none;
    }
  }
`;
