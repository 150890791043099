import { CircularProgress } from 'components';
import { useEffect, useState } from 'react';
import { Wrapper } from './Pending.style';

export default function Pending() {
  const data = ['pending expenses', 'unpaid receivables'];
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setAmount(0);
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <div className="data-rows">
        {data.map((el, index) => (
          <div className="data-row" key={`row-${index}`}>
            {loading ? (
              <CircularProgress />
            ) : amount === 0 ? (
              <h4 className="b-700"> &#8358; {amount.toFixed(2)}</h4>
            ) : (
              '-'
            )}

            <hr />
            <p className="capitalize">{el}</p>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
