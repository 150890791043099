import { api, addAuthorizationHeader } from './axios';
import { queryBuilder } from 'utils';

export const createMaterialTransferRequest = data => {
  return api.post('/create-material-transfer-request', data, addAuthorizationHeader());
};

export const getAllMaterialTransferRequest = ({ query }) => {
  return api.get(
    `/material-transfer-requests${queryBuilder(query, ['page', 'search', 'perPage'])}`,
    addAuthorizationHeader(),
  );
};

export const getTransferRequestByID = id => {
  return api.get(`/material-transfer-request/${id}`, addAuthorizationHeader());
};

export const updateMaterialTransferRequest = (id, data) => {
  return api.put(
    `/update-material-transfer-request/${id}`,
    data,
    addAuthorizationHeader(),
  );
};
