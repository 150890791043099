import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { getWidth, getHeight } from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import { ButtonWrapper } from 'components/Button/Button.style';

export default function DropdownMenu(props) {
  const endDecorator = props.loading ? (
    <CircularProgress />
  ) : (
    props.endDecorator
  );

  return (
    <ButtonWrapper
      className={props.className}
      width={getWidth(props.width, props.size)}
      height={getHeight(props.height)}
      loading={props.loading}
      outlined={props.outlined}
      contain={props.contain}
    >
      <Dropdown>
        <MenuButton
          disabled={props.disabled || (props.loading && true)}
          variant="plain"
          startDecorator={props.startDecorator}
          endDecorator={endDecorator}
          type={props.type}
        >
          {props.children}
        </MenuButton>
        {props.menuItems && (
          <Menu size="lg">
            {props.menuItems.map((item, index) => (
              <MenuItem key={index} onClick={item.onClick}>
                {item.text}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Dropdown>
    </ButtonWrapper>
  );
}
