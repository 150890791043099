import { useMediaQuery } from "react-responsive";
import { Wrapper } from "./PageWrapper.style";
import { useUserContext } from "context/UserContext";

const PageWrapper = ({ children, ...others }) => {
	const { isLoggedIn } = useUserContext();
	const isSmallDevice = useMediaQuery({ maxWidth: 768 });

	return (
		<Wrapper
			isLoggedIn={!!isLoggedIn}
			isSmallDevice={isSmallDevice}
			{...others}
		>
			{children}
		</Wrapper>
	);
};

export default PageWrapper;
