import Swal from 'sweetalert2';
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  BehanceOutlined,
} from '@ant-design/icons';
import {
  FinancesIcon,
  LeaseIcon,
  PeopleIcon,
  ProjectsIcon,
  ReportsIcon,
  RequestsIcon,
  SettingsIcon,
  WarehouseIcon,
  DashboardIcon,
} from 'assets/svg';
import {
  DASHBOARD_ROUTES_PATHS,
  PROJECT_ROUTES_PATHS,
  REQUEST_ROUTES_PATHS,
  STAFF_ROUTES_PATHS,
  SUPPLIERS_ROUTES_PATHS,
  WAREHOUSE_ROUTES_PATHS,
} from 'routes/paths';

import { MdDashboard, MdRequestQuote } from 'react-icons/md';
import { RiFileFill } from 'react-icons/ri';
import { FaWarehouse, FaUser, FaHandHoldingDroplet } from 'react-icons/fa6';
import { IoMdWallet } from 'react-icons/io';
import { BiSolidReport } from 'react-icons/bi';
import { IoSettingsSharp } from 'react-icons/io5';

const width = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
  isSmallDevice: '768px',
};

const height = {
  small: '400px',
  mid: '800px',
};

export const device = {
  mobileS: `(max-width: ${width.mobileS})`,
  mobileM: `(max-width: ${width.mobileM})`,
  mobileL: `(max-width: ${width.mobileL})`,
  tablet: `(max-width: ${width.tablet})`,
  laptop: `(max-width: ${width.laptop})`,
  laptopL: `(max-width: ${width.laptopL})`,
  desktop: `(max-width: ${width.desktop})`,
  desktopL: `(max-width: ${width.desktop})`,
  isSmallDevice: `(max-width: ${width.isSmallDevice})`,
};

export const deviceHeight = {
  small: `(max-height: ${height.small})`,
  mid: `(max-height: ${height.mid})`,
};

export const setToStorage = (key, value) => {
  let storedValue = JSON.stringify(value);
  localStorage.setItem(key, storedValue);
};

export const getFromStorage = key => {
  let value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const fireSwalSuccess = (message, callback = () => {}) => {
  Swal.fire({
    icon: 'success',
    toast: true,
    position: 'top',
    title: message,
    showConfirmButton: false,
    timer: 2000,
  }).then(() => {
    callback();
  });
};

export const fireSwalError = message => {
  Swal.fire({
    icon: 'error',
    toast: true,
    position: 'top-end',
    title: message,
    showConfirmButton: false,
    timer: 2000,
  });
};

export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const fireSwalConfirm = confirmFunction => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#E4410A',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
  }).then(result => {
    if (result.isConfirmed) {
      confirmFunction();
    }
  });
};

const siteURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://qualitrends.com';

export const copyToClipboard = async link => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(`${siteURL}${link}`);
  } else {
    document.execCommand('copy', true, `${siteURL}${link}`);
  }
  fireSwalSuccess('Link Copied');
};

export const prefixURL = process.env.REACT_APP_FILEURL;

export const socialMedia = [
  'facebook',
  'instagram',
  'twitter',
  'linkedIn',
  'tikTok',
];

export const socialMediaIcons = {
  facebook: <FacebookOutlined size={22} />,
  instagram: <InstagramOutlined size={22} />,
  twitter: <TwitterOutlined size={22} />,
  linkedIn: <LinkedinOutlined size={22} />,
  tikTok: <BehanceOutlined size={22} />,
};

export const logout = () => {
  localStorage.clear();
  window.location.reload();
};

export const formatAmount = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'NGN',
  minimumFractionDigits: 0,
});

export const navItems = [
  {
    title: 'Dashboard',
    // icon: <DashboardIcon />,
    url: DASHBOARD_ROUTES_PATHS.DASHBOARD_PAGE(),
    icon: <MdDashboard className="w-8 h-8" />,
    exact: true,
  },
  {
    title: 'Projects',
    icon: <RiFileFill className="w-8 h-8" />,
    hasSubmenu: true,
    submenu: [
      {
        title: 'All Projects',
        icon: null,
        url: PROJECT_ROUTES_PATHS.DASHBOARD_PAGE(),
        exact: true,
      },
      {
        title: 'Ongoing Projects',
        icon: null,
        url: PROJECT_ROUTES_PATHS.BY_STATUS_PAGE({ isOngoing: true }),
        exact: true,
      },
      {
        title: 'Completed Projects',
        icon: null,
        url: PROJECT_ROUTES_PATHS.BY_STATUS_PAGE({ isCompleted: true }),
        exact: true,
      },
    ],
  },
  {
    title: 'Warehouse',
    icon: <FaWarehouse className="w-8 h-8" />,
    hasSubmenu: true,
    submenu: [
      {
        title: 'Product List',
        icon: null,
        url: '/warehouse/products',
        exact: true,
      },
      {
        title: 'Warehouse Activities',
        icon: null,
        url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE(),
        exact: true,
      },
    ],
  },
  {
    title: 'Requests',
    url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE(),
    icon: <MdRequestQuote className="w-8 h-8" />,
    // icon: <RequestsIcon />,
    // url: '/requests',
  },
  {
    title: 'People',
    icon: <FaUser className="w-7 h-7" />,
    hasSubmenu: true,
    submenu: [
      {
        title: 'Customers',
        icon: null,
        url: '/customers',
      },
      {
        title: 'Staff',
        icon: null,
        url: STAFF_ROUTES_PATHS.DASHBOARD_PAGE(),
      },
      {
        title: 'Suppliers',
        icon: null,
        url: SUPPLIERS_ROUTES_PATHS.DASHBOARD_PAGE(),
      },
    ],
  },
  {
    title: 'Finances',
    icon: <IoMdWallet className="w-8 h-8" />,
    url: '/finances',
  },
  {
    title: 'Leases & Sales',
    icon: <FaHandHoldingDroplet className="w-8 h-8" />,
    url: '/leases',
  },
  {
    title: 'Reports',
    icon: <BiSolidReport className="w-8 h-8" />,
    url: '/reports',
  },
  {
    title: 'Settings',
    icon: <IoSettingsSharp className="w-8 h-8" />,
    url: '/settings',
  },
];

export const mobileNavItems = [
  {
    title: 'Dashboard',
    icon: <MdDashboard className="w-6 h-6" />,
    url: '/',
    exact: true,
  },
  {
    title: 'Projects',
    icon: <RiFileFill className="w-6 h-6" />,
    url: '/projects',
  },
  {
    title: 'Finances',
    icon: <IoMdWallet className="w-6 h-6" />,
    url: '/finances',
  },
  {
    title: 'Settings',
    icon: <IoSettingsSharp className="w-6 h-6" />,
    url: '/settings',
  },
];

export const titleDropdownOptions = [
  {
    id: '1',
    value: 'Master',
    text: 'Master',
  },
  {
    id: '2',
    value: 'Miss',
    text: 'Miss',
  },
  {
    id: '3',
    value: 'Mr',
    text: 'Mr',
  },
  {
    id: '4',
    value: 'Mrs',
    text: 'Mrs',
  },
];

export const states = [
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
  'FCT',
];
