import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  padding: 0.6rem 1rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 0.8rem;
  background: transparent;
  gap: 0.5rem;

  svg {
    width: 0.7142rem;
    height: 0.7142rem;
  }

  ${({ hexCode, lighterBackground }) =>
    hexCode
      ? css`
          color: ${hexCode};
          background-color: ${lighterBackground};

          svg circle {
            fill: ${hexCode};
          }
        `
      : css`
          &.red {
            color: #f00;
            background-color: rgba(255, 0, 0, 0.1);

            svg circle {
              fill: #f00;
            }
          }

          &.blue {
            color: #17a2b8;
            background-color: rgba(23, 162, 184, 0.1);

            svg circle {
              fill: #17a2b8;
            }
          }

          &.green {
            color: #008000;
            background-color: rgba(0, 128, 0, 0.1);

            svg circle {
              fill: #008000;
            }
          }

          &.orange {
            color: #ff5614;
            background-color: rgba(255, 94, 20, 0.1);

            svg circle {
              fill: #ff5614;
            }
          }
        `}
`;
