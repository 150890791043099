import { createAsyncThunk } from '@reduxjs/toolkit';
import * as PurchaseOrderApi from 'api/purchaseOrder';
import { handleNetworkRequest } from 'utils/networkRequest';

const createPurchaseOrder = createAsyncThunk(
  'purchaseOrder/createPurchaseOrder',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      PurchaseOrderApi.createPurchaseOrder(data),
    ),
);

const getPurchaseOrder = createAsyncThunk(
  'purchaseOrder/getPurchaseOrder',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => PurchaseOrderApi.getPurchaseOrder(id),
      {
        showSuccessMessage: false,
      },
    ),
);

const reviewPurchaseOrder = createAsyncThunk(
  'purchaseOrder/reviewPurchaseOrder',
  async ({ id, comment, status }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      PurchaseOrderApi.reviewPurchaseOrder(id, { comment, status }),
    ),
);

const updatePurchaseOrder = createAsyncThunk(
  'purchaseOrder/updatePurchaseOrder',
  async ({ id, ...data }, { dispatch }) => {
    return handleNetworkRequest(dispatch, () =>
      PurchaseOrderApi.updatePurchaseOrder(id, data),
    );
  },
);

const getPurchaseOrderHistory = createAsyncThunk(
  'purchaseOrder/getPurchaseOrderHistory',
  async ({ id, requestId }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => PurchaseOrderApi.getPurchaseOrderHistory(id, requestId),
      {
        showSuccessMessage: false,
      },
    ),
);

export {
  createPurchaseOrder,
  getPurchaseOrder,
  reviewPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrderHistory,
};
