import { createSlice } from '@reduxjs/toolkit';
import {
  createSupplier,
  updateSupplier,
  getSupplierById,
  getSupplierNames,
} from './actions';

const initialState = {
  loading: false,
  search_loading: false,
  pagination: null,
  activeSupplier: null,
  tableParams: {
    perPage: 10,
    page: 1,
    search: '',
    name: '',
  },
  supplierNames: [],
  suppliesNamesLoading: false,
};

const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getSupplierNames
    builder.addCase(getSupplierNames.pending, state => {
      state.suppliesNamesLoading = true;
    });
    builder.addCase(getSupplierNames.fulfilled, (state, action) => {
      state.supplierNames = action.payload.data.data;
      state.suppliesNamesLoading = false;
    });
    builder.addCase(getSupplierNames.rejected, state => {
      state.suppliesNamesLoading = false;
    });

    // createSupplier
    builder.addCase(createSupplier.pending, state => {
      state.loading = true;
    });
    builder.addCase(createSupplier.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(createSupplier.rejected, state => {
      state.loading = false;
    });

    // updateSupplier
    builder.addCase(updateSupplier.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateSupplier.fulfilled, (state, action) => {
      const data = action.payload.data.supplier;
      state.activeSupplier = data;
      state.loading = false;
    });
    builder.addCase(updateSupplier.rejected, state => {
      state.loading = false;
    });

    // getSupplierById
    builder.addCase(getSupplierById.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeSupplier = data;
    });
  },
});

export const {} = supplierSlice.actions;

export default supplierSlice.reducer;
