import React, { Suspense, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PageLoader, ModalGroup, Snackbar, Dimmer } from './components';
import PageRoutes from 'routes';
import './App.scss';
import PageLayout from 'layout/PageLayout';
import { DropdownOptionsGlobalStyle } from 'components/Dropdown/Dropdown.style';
import 'assets/scss/margins.scss';
import 'assets/scss/paddings.scss';

function App() {
  const theme = useMemo(
    () => ({
      black: '#000000',
      white: '#fff',
      grey: '#AFAFAF',
      red: '#D32600',
      primaryColor: '#ff6900',
      textBlack: '#2b2b29',
      darkBlack: '#1a1a1a',
      secondaryColor: 'red',
      greyText: '#5a5a5a',
      veryLightGrey: '#5a5a5a20',
      grey100: '#cbcfd3',
      milkyWhite: 'rgba(244, 248, 253, 0.30)',
      navyblue: '#173E62',

      // variables
      sideBarWidth: '19.8571rem',
      veryLightOrange: 'rgba(255, 105, 0, 0.1);',
    }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <PageLayout>
          <Suspense fallback={<PageLoader />}>
            <PageRoutes />
          </Suspense>
        </PageLayout>

        <DropdownOptionsGlobalStyle />
        <Snackbar />
        <Dimmer />
        <ModalGroup />
      </Router>
    </ThemeProvider>
  );
}

export default App;
