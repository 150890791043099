import styled from 'styled-components';
import { getConditionalClass } from 'utils';

export const CheckboxWrapper = styled.div.attrs(props => ({
  className: `input-wrapper ${getConditionalClass(props.className)}`,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Mui-checked {
    --Icon-color: ${props => props.theme.primaryColor};
    background: transparent;
    color: ${props => props.theme.primaryColor};

    svg {
      border: 1px solid ${props => props.theme.primaryColor};
      background: white;
    }
  }
`;
