import { createSlice } from '@reduxjs/toolkit';
import { getColors } from './actions';

const initialState = {
  colorsLoading: false,
  colors: [],
};

const colorSlice = createSlice({
  name: 'color',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getColors
    builder.addCase(getColors.pending, state => {
      state.colorsLoading = true;
    });
    builder.addCase(getColors.fulfilled, (state, action) => {
      const { colors } = action.payload.data;
      state.colors = colors;
      state.colorsLoading = false;
    });
    builder.addCase(getColors.rejected, state => {
      state.colorsLoading = false;
    });
  },
});

export const {} = colorSlice.actions;

export default colorSlice.reducer;
