import styled from 'styled-components';

export const TableAddButton = styled.button`
  display: block !important;
  margin-left: auto !important;
  margin-top: -1rem !important;
  font-size: 1rem !important;
  border: none;
  background: transparent;

  &:hover {
    color: ${props => props.theme.primaryColor} !important;
    background: ${props => props.theme.white} !important;
  }

  svg {
    font-size: 1.4rem;
  }
`;

export const TableWrapper = styled.div`
  border: 1px solid rgba(90, 90, 90, 0.6);
  border-radius: 3px 3px 0px 0px;
  margin-bottom: 20px;

  table {
    thead {
      --TableCell-headBackground: ${props => props.theme.veryLightOrange};
      font-size: 1rem;
      font-weight: 700;

      tr {
        > th {
          vertical-align: middle;
        }
      }
    }

    tbody {
      font-family: Roboto;
      font-size: 0.9rem;
      font-weight: 400;

      tr {
        background: ${props => props.theme.white};

        &:hover {
          background: ${props => props.theme.white};

          &[aria-disabled='true'] {
            background: ${props => props.theme.white};
          }
        }
      }

      td {
        .table-btn {
          padding-inline: 0.5rem;
          font-size: 1.4rem;
          border: none;
          border-radius: 0.4rem;
          background: transparent;

          &.edit-save {
            color: green;

            &:hover {
              background: green;
              color: white;
            }
          }

          &.edit-cancel {
            color: red;

            &:hover {
              background: red;
              color: white;
            }
          }
        }
      }
    }
  }
`;
