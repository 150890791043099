import styled, { css } from 'styled-components';
import { getConditionalClass } from 'utils';

export const InputWrapper = styled.div.attrs(props => ({
  className: `input-wrapper ${getConditionalClass(props.className)}`,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.hidden {
    display: none;
  }

  .MuiInput-root {
    ${({ width }) =>
      width
        ? css`
            width: ${width};
          `
        : css`
            min-width: 2.5rem;
          `}
    min-width: ${props => props.width || '2.5rem'};
    min-height: ${props => props.height || '2.8571rem'};
    border-radius: 0.2142rem;
    border: 0.07142rem solid #5a5a5a80;
    --Input-focusedHighlight: ${props => props.theme.primaryColor} !important;
    background: ${props => props.theme.milkyWhite};
    &.Mui-focused::before {
      box-shadow: 0 0 0 1px ${props => props.theme.primaryColor};
      border: 0.07142rem solid ${props => props.theme.primaryColor};
    }

    .MuiCircularProgress-root {
      ${({ loading }) =>
        loading
          ? css`
              circle:first-of-type {
                stroke: ${props => props.theme.veryLightOrange};
              }
              circle:last-of-type {
                stroke: ${props => props.theme.primaryColor};
              }
            `
          : css``}
    }
  }
`;
