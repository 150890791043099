import { useContext, useState, createContext } from 'react';
export const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [content, setContent] = useState('');
  const [onClose, setOnClose] = useState(null);

  const changeModalType = modal => {
    setIsOpenModal(true);
  };

  const showModal = (title, content) => {
    setIsOpenModal(true);
    setModalTitle(title);
    setContent(content);
  };

  return (
    <ModalContext.Provider
      value={{
        changeModalType,
        isOpenModal,
        setIsOpenModal,
        modalTitle,
        setModalTitle,
        content,
        setContent,
        onClose,
        setOnClose,
        showModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};

export default ModalProvider;
