import uuid from 'react-uuid';
import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const extractQueryParams = url => {
  const [pathname, queryString] = url.split('?'); // Get the query string part of the URL

  if (!queryString) return [pathname, {}]; // Return an empty object if there are no query parameters

  const queryParams = {};
  const pairs = queryString.split('&');

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    queryParams[key] = decodeURIComponent(value || ''); // Decoding URI component
  });

  return [pathname, queryParams];
};

export const checkActiveLink = (location, url, isExact) => {
  const currentPathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const availableQueries = Object.fromEntries([...searchParams]);

  if (isExact) {
    const [urlPathname, urlQueries] = extractQueryParams(url);

    const urlQueriesArr = Object.entries(urlQueries);

    const totalQueriesAvailableInUrl = urlQueriesArr.reduce(
      (accumulator, currentValue) => {
        const key = currentValue[0];
        const value = currentValue[1];

        return availableQueries[key] && availableQueries[key] === value
          ? accumulator + 1
          : accumulator;
      },
      0,
    );

    return (
      currentPathname === urlPathname &&
      totalQueriesAvailableInUrl === urlQueriesArr.length
    );
  }

  return currentPathname.includes(url);
};

export const getConditionalClass = (booleanCondition, className) =>
  booleanCondition ? className : '';

export const setBooleanProp = bool => (bool ? 'true' : undefined);

export const initPaginationData = () => ({
  total: 0,
  total_pages: 0,
  perPage: 0,
  page: 0,
  current_page_total: 0,
  from: 0,
  to: 0,
});

export const urlBuilder = pathsArr => `/${pathsArr.join('/')}`;

export const queryBuilder = (queryObj, keys) => {
  let reqQuery = '?';

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = queryObj[key];
    reqQuery += value ? `&${key}=${value}` : '';
  }

  return reqQuery === '?' ? '' : reqQuery;
};

export const capitalize = (words, delimiter) =>
  words
    .split(delimiter)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');

export const formatMoney = amount => {
  const num = parseFloat(amount);
  return num.toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
  });
};

export const deepClone = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const clonedObj = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }
  return clonedObj;
};

export const generateUniqueId = () => uuid();
