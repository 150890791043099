import { createAsyncThunk } from '@reduxjs/toolkit';
import * as StaffApi from 'api/staff';
import { handleNetworkRequest } from 'utils/networkRequest';

const createStaffEmploymentType = createAsyncThunk(
  'staff/createStaffEmploymentType',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      StaffApi.createStaffEmploymentType({ name, description }),
    ),
);

const createStaffPosition = createAsyncThunk(
  'staff/createStaffPosition',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      StaffApi.createStaffPosition({ name, description }),
    ),
);

const getStaffEmploymentTypes = createAsyncThunk(
  'staff/getStaffEmploymentTypes',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => StaffApi.getStaffEmploymentTypes(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

const getStaffPositions = createAsyncThunk(
  'staff/getStaffPositions',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => StaffApi.getStaffPositions(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

const createStaff = createAsyncThunk(
  'staff/createStaff',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () => StaffApi.createStaff(data)),
);

const getAllStaff = createAsyncThunk(
  'staff/getAllStaff',
  async (data, { dispatch }) => {
    const { perPage, showPageLoader = true, customErrorMessage } = data;

    return handleNetworkRequest(
      dispatch,
      () => StaffApi.getAllStaff({ query: { perPage } }),
      {
        showPageLoader,
        showSuccessMessage: false,
        customErrorMessage,
      },
    );
  },
);

const getStaffById = createAsyncThunk(
  'staff/getStaffById',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(dispatch, () => StaffApi.getStaffById(id), {
      showSuccessMessage: false,
    }),
);

const updateStaff = createAsyncThunk(
  'staff/updateStaff',
  async ({ id, ...data }, { dispatch }) =>
    handleNetworkRequest(dispatch, () => StaffApi.updateStaff(id, data)),
);

export {
  createStaffEmploymentType,
  createStaffPosition,
  getStaffEmploymentTypes,
  getStaffPositions,
  createStaff,
  getAllStaff,
  getStaffById,
  updateStaff,
};
