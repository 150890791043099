import { createSlice } from '@reduxjs/toolkit';
import {
  getPurchaseOrder,
  reviewPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrderHistory,
} from './actions';

const initialState = {
  activePurchaseOrder: null,
  activePurchaseOrderHistory: null,
};

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getPurchaseOrder
    builder.addCase(getPurchaseOrder.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activePurchaseOrder = data;
    });

    // reviewPurchaseOrder
    builder.addCase(reviewPurchaseOrder.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activePurchaseOrder = data;
    });

    // updatePurchaseOrder
    builder.addCase(updatePurchaseOrder.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activePurchaseOrder = data;
    });

    // getPurchaseOrderHistory
    builder.addCase(getPurchaseOrderHistory.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activePurchaseOrderHistory = data;
    });
  },
});

export default purchaseOrderSlice.reducer;
