import { api, addAuthorizationHeader } from './axios';
import { queryBuilder } from 'utils';

import { CATEGORY_TYPES, createCategory, getCategoryByType } from './category';

export const createExpenseRequestCategory = data => {
  const updatedData = {
    ...data,
    type: CATEGORY_TYPES.EXPENSE_REQUEST_CATEGORY,
  };
  return createCategory(updatedData);
};

export const getExpenseRequestCategories = () => {
  return getCategoryByType([CATEGORY_TYPES.EXPENSE_REQUEST_CATEGORY]);
};

export const createExpenseRequest = data => {
  return api.post('/create-expense-request', data, addAuthorizationHeader());
};

export const updateExpenseRequest = (id, data) => {
  return api.put(
    `/update-expense-request/${id}`,
    data,
    addAuthorizationHeader(),
  );
};

export const reviewExpenseRequest = (id, data) => {
  return api.put(
    `/review-expense-request/${id}`,
    data,
    addAuthorizationHeader(),
  );
};

export const getAllExpenseRequests = ({ query }) => {
  return api.get(
    `/expense-requests${queryBuilder(query, ['page', 'search', 'perPage'])}`,
    addAuthorizationHeader(),
  );
};

export const getProjectExpenseRequests = ({ projectId, query }) => {
  return api.get(
    `/project-expense-requests/${projectId}${queryBuilder(query, [
      'page',
      'search',
      'perPage',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getExpenseRequest = id => {
  return api.get(`/expense-request/${id}`, addAuthorizationHeader());
};

export const getExpenseRequestHistory = (id, requestId) => {
  return api.get(
    `/expense-request/${requestId}/history/${id}`,
    addAuthorizationHeader(),
  );
};
