import { useState, useEffect } from 'react';
import { CircularProgress } from 'components';
import { MenuHorizontal, MenuVertical } from 'assets/svg';
import { Wrapper, FieldHeader, Header } from './PendingRequests.style';

export default function PendingRequests() {
  const data = [
    {
      type: 'purchase order',
    },
    {
      type: 'material transfer',
    },
    {
      type: 'expenses',
    },
  ];
  const [requests, setRequests] = useState(data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      !requests && setRequests(data);
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <Header className="flex">
        <h3 className="capitalize b-700">Pending requests</h3>
        <MenuVertical />
      </Header>

      <FieldHeader>
        <div className="field-titles">
          <p className="capitalize t-sm">Project code</p>
          <p className="capitalize t-sm">request type</p>
          <p className="capitalize t-sm">action</p>
        </div>
        {requests?.map((transaction, key) => (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <li className="data-row" key={`data-row-${key}`}>
                <p className="t-sm">100</p>
                <p className="capitalize t-sm">{transaction.type}</p>
                <MenuHorizontal />
              </li>
            )}
          </>
        ))}
      </FieldHeader>
    </Wrapper>
  );
}
