import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth/reducer';
import UserReducer from './user/reducer';
import StaffReducer from './staff/reducer';
import snackbarReducer from './snackbar/reducer';
import SupplierReducer from './supplier/reducer';
import dimmerReducer from './dimmer/reducer';
import warehouseReducer from './warehouse/reducer';
import expenseRequestReducer from './expenseRequest/reducer';
import projectsReducer from './projects/reducer';
import supplyRequestReducer from './supplyRequest/reducer';
import transferRequestReducer from './MaterialTransferRequest/reducer'
import purchaseOrderReducer from './purchaseOrder/reducer';
import tableAndPaginationReducer from './tableAndPagination/reducer';
import colorReducer from './color/reducer';
import tabReducer from './tab/reducer';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    user: UserReducer,
    staff: StaffReducer,
    snackbar: snackbarReducer,
    supplier: SupplierReducer,
    dimmer: dimmerReducer,
    warehouse: warehouseReducer,
    expenseRequest: expenseRequestReducer,
    purchaseOrder: purchaseOrderReducer,
    projects: projectsReducer,
    supplyRequest: supplyRequestReducer,
    transferRequest: transferRequestReducer,
    tableAndPagination: tableAndPaginationReducer,
    color: colorReducer,
    tab: tabReducer,
  },
});

export default store;
