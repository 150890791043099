import styled from 'styled-components';
import { getConditionalClass } from 'utils';

export const TextAreaWrapper = styled.div.attrs(props => ({
  className: `textarea-wrapper ${getConditionalClass(props.className)}`,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .MuiTextarea-root {
    background: ${props => props.theme.milkyWhite};
    min-width: ${props => props.width || '2.5rem'};
    min-height: ${props => props.height || '2.8571rem'};
    border-radius: 0.2142rem;
    border: 0.07142rem solid #5a5a5a80;
    --Textarea-focusedHighlight: ${props =>
      props.theme.primaryColor} !important;
  }
`;
