import * as React from 'react';
import TabsElement from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { TabsWrapper } from './Tabs.style';
import { updateActiveTabIndex } from 'store/tab/reducer';
import { useSelector, useDispatch } from 'react-redux';
import { generateUniqueId } from 'utils';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useGotoTab from 'hooks/useGotoTab';

const Tabs = ({ config = [], onChange, setTabId, className }) => {
  const [tabId] = useState(generateUniqueId());
  const { activeTabIndex, defaultIndex } = useSelector(({ tab }) => tab);
  const value = activeTabIndex[tabId] ?? defaultIndex;

  const navigate = useNavigate();

  const onClick = (e, url) => {
    e.preventDefault();
    navigate(url);
  };

  const dispatch = useDispatch();

  const onTabChange = (e, tabIndex) => {
    dispatch(updateActiveTabIndex({ tabIndex, tabId }));

    if (onChange) onChange({ tabIndex, tabId });
  };

  useEffect(() => {
    if (setTabId && tabId) {
      setTabId(tabId);
    }
  }, [tabId, setTabId]);

  useGotoTab(tabId, config);

  return (
    <TabsWrapper className={className}>
      <TabsElement
        aria-label="Basic tabs"
        size="lg"
        onChange={onTabChange}
        value={value}
      >
        <TabList>
          {config.map((item, index) =>
            item.url ? (
              <a
                href={item.url}
                onClick={e => onClick(e, item.url)}
                key={`tab-title-${index}`}
              >
                <Tab>{item.title}</Tab>
              </a>
            ) : (
              <Tab key={`tab-title-${index}`}>{item.title}</Tab>
            ),
          )}
        </TabList>

        {config.map((item, index) => (
          <TabPanel key={`tab-panel-${index}`} value={index}>
            {item.template}
          </TabPanel>
        ))}
      </TabsElement>
    </TabsWrapper>
  );
};

export default Tabs;
