import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  message: '',
  isError: false,
  delay: 5000,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackBar: (state, action) => {
      const { show, message, isError, delay } = action.payload || {};

      state.show = show;
      state.message = message || '';
      state.isError = isError || false;
      state.delay = delay || 5000;
    },
  },
});

export const { showSnackBar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
