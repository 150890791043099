import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { DotIcon } from 'assets/svg';
import { Wrapper, PaginationWrapper, DataRow, FieldHeader } from './PaginatedItems.style';

export default function PaginatedItems({ data, fields, noPagination }) {
  const [itemOffset, setItemOffset] = useState(0);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setPage(event.selected);
    setItemOffset(newOffset);
  }; 

  const assignClass = (key, id, value) => {
    let className = 'capitalize t-sm';
    let isStatus = key === 'status';
    let confirmationStatus = key === 'confirmed';
    let deliveryStatus = key === 'delivery_status';

    if (isStatus || deliveryStatus) {
      className += ` ${value}`;
      return (
        <p key={`status-${id}`} className={`${className} status-badge`}>
          <DotIcon className="dot-icon" />
          {value}
        </p>
      );
    } else if (confirmationStatus) {
      value
        ? (className += ' confirm-badge true')
        : (className += ' confirm-badge');
      return (
        <p key={`confirm-badge-${id}`} className={className}>
          {value ? 'Confirmed' : 'Confirm'}
        </p>
      );
    }

    return (
      <p key={`value-${id}`} className={className}>
        {value}
      </p>
    );
  };

  return (
    <>
      <Wrapper>
        <FieldHeader>
          <div className="field-titles">
            {fields.map((field, key) => (
              <p key={`field-${key}`} className="capitalize t-sm">
                {field}
              </p>
            ))}
          </div>
          {currentItems.length > 0 ? (
            <>
              {currentItems?.map((item, key) => (
                <>
                  <DataRow key={`data-row-${key}`}>
                    {Object.entries(item).map((value, id) => {
                      return assignClass(value[0], id, value[1]);
                    })}
                  </DataRow>
                </>
              ))}
            </>
          ) : (
            <li className="empty">No data available</li>
          )}
        </FieldHeader>
      </Wrapper>
      <PaginationWrapper
        className={noPagination ? 'nopagination' : 'pagination'}
      >
        <p className="grey">
          Showing {itemOffset} -{' '}
          {endOffset > data.length ? data.length : endOffset} of {data.length}
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          containerClassName="pagination-row"
          pageClassName="t-sm"
          previousClassName={page === 0 ? 'previous disabled' : 'previous'}
          nextClassName={itemOffset >= itemsPerPage ? 'next disabled' : 'next'}
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
        />
      </PaginationWrapper>
    </>
  );
}

