import styled from 'styled-components';

export const Layout = styled.main.attrs(() => ({
  className: 'layout',
}))`
  height: 100vh;
  display: grid;
  grid-template-columns: ${props => props.theme.sideBarWidth} 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'side-nav top-nav'
    'side-nav main-page';

  .top-nav {
    grid-area: top-nav;
  }

  .side-nav {
    grid-area: side-nav;
  }

  .main-page {
    grid-area: main-page;
  }
`;
