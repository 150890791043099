import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ExpenseRequestApi from 'api/expenseRequests';
import { handleNetworkRequest } from 'utils/networkRequest';

const createExpenseRequestCategory = createAsyncThunk(
  'expenseRequest/createExpenseRequestCategory',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ExpenseRequestApi.createExpenseRequestCategory({ name, description }),
    ),
);

const getExpenseRequestCategories = createAsyncThunk(
  'expenseRequest/getExpenseRequestCategories',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => ExpenseRequestApi.getExpenseRequestCategories(),
      {
        showPageLoader: false,
        showSuccessMessage: false,
      },
    ),
);

const createExpenseRequest = createAsyncThunk(
  'expenseRequest/createExpenseRequest',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ExpenseRequestApi.createExpenseRequest(data),
    ),
);

const getExpenseRequest = createAsyncThunk(
  'expenseRequest/getExpenseRequest',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => ExpenseRequestApi.getExpenseRequest(id),
      {
        showSuccessMessage: false,
      },
    ),
);

const reviewExpenseRequest = createAsyncThunk(
  'expenseRequest/reviewExpenseRequest',
  async ({ id, comment, status }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ExpenseRequestApi.reviewExpenseRequest(id, { comment, status }),
    ),
);

const updateExpenseRequest = createAsyncThunk(
  'expenseRequest/updateExpenseRequest',
  async ({ id, ...data }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ExpenseRequestApi.updateExpenseRequest(id, data),
    ),
);

const getExpenseRequestHistory = createAsyncThunk(
  'expenseRequest/getExpenseRequestHistory',
  async ({ id, requestId }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => ExpenseRequestApi.getExpenseRequestHistory(id, requestId),
      {
        showSuccessMessage: false,
      },
    ),
);

export {
  createExpenseRequest,
  createExpenseRequestCategory,
  getExpenseRequestCategories,
  getExpenseRequest,
  reviewExpenseRequest,
  updateExpenseRequest,
  getExpenseRequestHistory,
};
