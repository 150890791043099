import * as React from 'react';
import InputElement from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import { InputWrapper } from './Input.style';
import { CircularProgress } from 'components';

const Input = props => {
  const startDecorator = false ? <CircularProgress /> : props.startDecorator;

  return (
    <InputWrapper
      width={props.width}
      height={props.height}
      className={props.className}
      loading={props.loading}
    >
      <FormControl error={!!props.error || !!props.errorMessage}>
        {/* Label  */}
        {props.label && <FormLabel style={{ fontSize: '16px', fontWeight: 400 }}>{props.label}</FormLabel>}

        {/* Input  */}
        <InputElement
          startDecorator={startDecorator}
          placeholder={props.placeholder}
          error={props.error}
          value={props.value}
          endDecorator={props.endDecorator}
          fullWidth={props.fullWidth}
          onChange={props.onChange}
          name={props.name}
          type={props.type}
        />

        {/* Error Message  */}
        {props.errorMessage && (
          <FormHelperText>{props.errorMessage}</FormHelperText>
        )}
      </FormControl>
    </InputWrapper>
  );
};

export default Input;
