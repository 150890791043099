import { TableWrapper, TableAddButton } from './Table.style';
import TableElement from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { useEffect, useState } from 'react';
import { deepClone, generateUniqueId } from 'utils';
import { Input, Select } from 'components';
import { ICONS } from 'assets/svg';
import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const EDITABLE_INPUT_TYPE = {
  INPUT: 'INPUT',
  SELECT: 'SELECT',
};

const getRowValue = (row, key) => {
  if (Array.isArray(key)) {
    let value = row;
    for (const k of key) {
      if (value && typeof value === 'object' && k in value) {
        value = value[k];
      } else {
        return undefined; // Key not found or value is not an object
      }
    }
    return value;
  } else {
    return row[key];
  }
};

const Table = ({
  config,
  className,
  editable = false,
  dataStructure,
  yupValidation = {},
  setTableItemsInParent,
  setTableHasErrors,
  hasTotalRow,
  totalValue,
}) => {
  const { columns = [], rows = [] } = config || {};

  const variableNameInFormik = 'tableData';

  const validationSchema = Yup.object().shape({
    [variableNameInFormik]: Yup.array().of(Yup.object().shape(yupValidation)),
  });

  const formik = useFormik({
    initialValues: { [variableNameInFormik]: [] },
    validationSchema,
    validateOnChange: true,
  });

  const emitTableValues = () => {
    const errorsExist = formik.errors[variableNameInFormik]?.length > 0;

    if (errorsExist) {
      setTableHasErrors && setTableHasErrors(true);
      setTableItemsInParent && setTableItemsInParent(null);
    } else {
      setTableHasErrors && setTableHasErrors(false);
      setTableItemsInParent &&
        setTableItemsInParent(
          formik.values[variableNameInFormik].map(item => {
            const element = {
              ...item,
            };
            delete element.copyId;
            delete element.edit;

            return element;
          }),
        );
    }
  };

  const [activeEditsInitialState, setActiveEditsInitialState] = useState([]);

  const noTableData = columns.length === 0 && rows.length === 0;

  const genereteRow = (structure, edit = false) => ({
    copyId: generateUniqueId(),
    edit,
    ...deepClone(structure),
  });

  const onEdit = rowData => {
    rowData.edit = true;
    const currentRowCopy = deepClone(rowData);
    setActiveEditsInitialState([...activeEditsInitialState, currentRowCopy]);
  };

  const onDelete = rowData => {
    const data = formik.values[variableNameInFormik];
    formik.setFieldValue(
      variableNameInFormik,
      data.filter(item => item.copyId !== rowData.copyId),
    );

    setActiveEditsInitialState(
      activeEditsInitialState.filter(item => item.copyId !== rowData.copyId),
    );
  };

  const onAddRow = () => {
    const data = formik.values[variableNameInFormik];

    const newRow = genereteRow(dataStructure, true);

    formik.setFieldValue(variableNameInFormik, [...data, newRow]);

    setActiveEditsInitialState([...activeEditsInitialState, newRow]);
  };

  const onSaveEdit = rowData => {
    rowData.edit = false;
    setActiveEditsInitialState(
      activeEditsInitialState.filter(item => item.copyId !== rowData.copyId),
    );
  };

  const onCancelEdit = rowData => {
    const data = formik.values[variableNameInFormik];
    const temp = [...data];
    const rowDataIndex = temp.findIndex(item => item.copyId === rowData.copyId);

    const rowInitialState = activeEditsInitialState.find(
      item => item.copyId === rowData.copyId,
    );

    temp.splice(rowDataIndex, 1, { ...rowInitialState, edit: false });

    setActiveEditsInitialState(
      activeEditsInitialState.filter(item => item.copyId !== rowData.copyId),
    );

    formik.setFieldValue(variableNameInFormik, temp);
  };

  const onChange = (value, key, rowData) => {
    const data = formik.values[variableNameInFormik];

    const temp = [...data];
    const rowDataIndex = temp.findIndex(item => item.copyId === rowData.copyId);

    const row = temp.find(item => item.copyId === rowData.copyId);

    const updatedData = { ...row, [key]: value };

    temp.splice(rowDataIndex, 1, updatedData);

    formik.setFieldValue(variableNameInFormik, temp);
  };

  useEffect(() => {
    if (editable) {
      const copy = rows.map(row => genereteRow(row));
      formik.setFieldValue(variableNameInFormik, copy);
    }
  }, []);

  useEffect(() => {
    emitTableValues();
  }, [formik.values, formik.isValid]);

  const rowsData = editable ? formik.values[variableNameInFormik] : rows;

  const rowsExist = rowsData && rowsData.length > 0;

  const inputHeight = '2.2rem';

  const getErrorMessage = (index, key) =>
    formik.errors[variableNameInFormik]?.[index]?.[key];

  return (
    <>
      <TableWrapper className={className}>
        <Sheet sx={{ overflow: 'auto' }}>
          {noTableData ? (
            <div>No table data</div>
          ) : (
            <TableElement
              aria-label="basic table"
              borderAxis="x"
              hoverRow
              stickyHeader
            >
              <thead>
                <tr>
                  {columns.map((data, index) => (
                    <th key={`header-${index}`} style={{ width: data.width }}>
                      {data.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rowsExist ? (
                  <>
                    {rowsData.map((row, rowIndex) => (
                      <tr key={`row-${rowIndex}`}>
                        {columns.map((column, columnIndex) => {
                          if (row.edit && column.isEditableColumn) {
                            if (column.inputType === EDITABLE_INPUT_TYPE.INPUT)
                              return (
                                <td key={`row-data-${columnIndex}`}>
                                  <Input
                                    placeholder={column.inputPlaceholder}
                                    value={getRowValue(row, column.key)}
                                    onChange={({ target: { value } }) => {
                                      onChange(value, column.key, row);
                                    }}
                                    height={inputHeight}
                                    errorMessage={getErrorMessage(
                                      rowIndex,
                                      column.key,
                                    )}
                                  />
                                </td>
                              );

                            if (column.inputType === EDITABLE_INPUT_TYPE.SELECT)
                              return (
                                <td key={`row-data-${columnIndex}`}>
                                  <Select
                                    placeholder={column.inputPlaceholder}
                                    value={getRowValue(row, column.key)}
                                    onChange={({ target: { value } }) => {
                                      onChange(value, column.key, row);
                                    }}
                                    options={column.inputOptions}
                                    loading={false}
                                    height={inputHeight}
                                    errorMessage={getErrorMessage(
                                      rowIndex,
                                      column.key,
                                    )}
                                  />
                                </td>
                              );
                          }

                          return (
                            <td key={`row-data-${columnIndex}`}>
                              {row.edit && column.isAction ? (
                                <div className="flex">
                                  <button
                                    type="button"
                                    className="table-btn edit-save"
                                    onClick={() => onSaveEdit(row)}
                                  >
                                    <Icon icon={ICONS.Check} />
                                  </button>

                                  <button
                                    type="button"
                                    className="table-btn edit-cancel"
                                    onClick={() => onCancelEdit(row)}
                                  >
                                    <Icon icon={ICONS.Close} />
                                  </button>
                                </div>
                              ) : column.template ? (
                                column.template(
                                  row,
                                  rowIndex,
                                  column.isAction ? { onEdit, onDelete } : {},
                                )
                              ) : (
                                getRowValue(row, column.key)
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    {hasTotalRow && (
                      <tr aria-disabled="true">
                        {columns.map((column, columnIndex) => {
                          let value = '';

                          if (columnIndex === 0) {
                            value = <strong>Total</strong>;
                          } else if (column.isTotalColumn && totalValue) {
                            value = <strong>{totalValue}</strong>;
                          } else if (column.isTotalColumn) {
                            value = (
                              <strong>{column.totalFunc(rowsData)}</strong>
                            );
                          }

                          return (
                            <td key={`total-td-${columnIndex}`}>{value}</td>
                          );
                        })}
                      </tr>
                    )}
                  </>
                ) : (
                  <tr aria-disabled="true">
                    <td colSpan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </TableElement>
          )}
        </Sheet>
      </TableWrapper>
      {editable && (
        <TableAddButton onClick={onAddRow} type="button">
          <Icon icon={ICONS.Plus} className="m-r-xs" />
          Add
        </TableAddButton>
      )}
    </>
  );
};

export default Table;
