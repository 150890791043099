import { CheckmarkIcon, CloseIcon, CancelIcon } from 'assets/svg';
import { useEffect, useState } from 'react';
import { Modal } from './Snackbar.style';
import { useSelector, useDispatch } from 'react-redux';
import { showSnackBar } from 'store/snackbar/reducer';

const Snackbar = () => {
  const dispatch = useDispatch();

  const { show, isError, message, delay } = useSelector(
    ({ snackbar }) => snackbar,
  );

  const [timer, setTimer] = useState(null);

  const close = () =>
    dispatch(
      showSnackBar({
        show: false,
        isError: false,
        message: '',
        delay: 5000,
      }),
    );

  const cancelTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (show) {
      setTimer(setTimeout(close, delay));
    }
  }, [show]);

  return (
    <Modal
      className="modal-wrapper"
      containerClassName="modal-container"
      closeOnOuterClick={true}
      show={show}
      onClose={cancelTimer}
    >
      <CloseIcon className="close-icon" onClick={close} />

      <div className="content">
        {isError ? (
          <CancelIcon className="cancel-icon" />
        ) : (
          <CheckmarkIcon className="cancel-icon" />
        )}

        <h6>{message}</h6>
      </div>
    </Modal>
  );
};

export default Snackbar;
