import styled from 'styled-components';
import SimpleModal from 'simple-react-modal';
import { device } from 'constants';

export const Modal = styled(SimpleModal)`
    &.modal-wrapper {
        position: fixed;
        inset: 0;
        z-index: 10;
        transition: opacity 1s ease-in 0s;
        background: #01050cb2;
        backdrop-filter: blur(0.0714rem);
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-container {
            position: relative;
            min-width: 1rem;
            max-width: 80%;
            background: white;
            border-radius: 6px;
            max-height: 95vh;
            overflow-y: auto;

            @media (max-width: 520px) {
              width: 100%;
              max-width: none;
              max-height: 100vh;
            }

            @media (min-width: 1500px) {
              max-width: 65%;
            }
            
            .header {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 24px;
                border-bottom: 1px solid ${props => props.theme.greyText};
                gap: 5rem;

                .title {
                  font-family: Roboto;
                  font-weight: 600;
                  color: ${props => props.theme.greyText};
                  font-size: 1.2rem;
                }

                svg {
                  height 16px;
                  width 16px;
                  cursor: pointer;

                  path {
                    stroke: ${props => props.theme.greyText};
                  }

                  &:hover {                  
                    path {
                      stroke: ${props => props.theme.primaryColor};
                    }
                  }
                }
            }

            .content {
              max-height: 85vh;
              overflow-y: auto;
              padding: 16px 24px;
            }
        }
    }
`;

export const Form = styled.form`
  .form-row:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const FormColumn = styled.div.attrs(() => ({
  className: `form-column`,
}))`
  align-self: flex-start;
`;

export const FormRow = styled.div.attrs(() => ({
  className: `form-row`,
}))`
  display: flex;
  align-items: start;
  gap: 0;
  flex-direction: column;
  color: ${props => props.theme.darkBlack};

  @media (min-width: 1100px) {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  @media (max-width: 700px) {
    flex-direction: row;
    gap: 0.8rem;
    justify-content: flex-start;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }

  label {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    width: fit-content;
    align-self: flex-start;
    padding-top: 0.5rem;

    @media (min-width: 1100px) {
      width: 10rem;
    }

    @media (max-width: 700px) {
      width: 10rem;
    }

    @media ${device.mobileL} {
      width: fit-content;
    }

    &.required::after {
      content: '*';
      color: red;
      margin-left: 0.3rem;
      font-weight: 900;
    }
  }
`;

export const Row = styled.div.attrs(() => ({
  className: `form-row`,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  &.no-justify-content {
    justify-content: initial;
  }

  @media ${device.isSmallDevice} {
    flex-wrap: wrap;
  }
`;

export const CreateButton = styled.div.attrs(() => ({
  className: ``,
}))`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0.5rem;

  &:hover {
    background-color: #f5f5f5;
    width: fit-content;
  }

  svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: ${props => props.theme.primaryColor};
    }
  }

  p {
    font-size: 1rem;
    color: ${props => props.theme.primaryColor};
  }
`;
