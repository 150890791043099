import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextArea, Button } from 'components';
import { Wrapper } from './ReviewRequest.style';
import { useDispatch } from 'react-redux';

const ReviewRequest = ({ onReview, id }) => {
  const dispatch = useDispatch();
  const initialValues = {
    comment: '',
    id,
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required('Please enter review comment'),
  });

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      dispatch(onReview(values));
    },
  });

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <label className="required">Review comment</label>
        <TextArea
          name="comment"
          placeholder="Enter comment"
          value={values.comment}
          errorMessage={errors.comment}
          onChange={handleChange}
          height="132px"
        />

        <div className="flex j-center flex-gap-m align-center btn-row">
          <Button
            size="large"
            outlined
            type="submit"
            onClick={() =>
              handleChange({ target: { name: 'status', value: 'declined' } })
            }
          >
            Decline
          </Button>
          <Button
            size="large"
            type="submit"
            onClick={() =>
              handleChange({ target: { name: 'status', value: 'approved' } })
            }
          >
            Approve
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};

export default ReviewRequest;
