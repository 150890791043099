import { api, addAuthorizationHeader } from './axios';
import { queryBuilder } from 'utils';

export const createSupplyRequest = data => {
  return api.post('/create-supply-request', data, addAuthorizationHeader());
};

export const getAllSupplyRequests = ({ query }) => {
  return api.get(
    `/supply-requests${queryBuilder(query, ['page', 'search', 'perPage'])}`,
    addAuthorizationHeader(),
  );
};

export const getProjectSupplyRequests = ({ projectId, query }) => {
  return api.get(
    `/project-supply-requests/${projectId}${queryBuilder(query, [
      'page',
      'search',
      'perPage',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getSupplyRequest = id => {
  return api.get(`/supply-request/${id}`, addAuthorizationHeader());
};

export const reviewSupplyRequest = (id, data) => {
  return api.put(
    `/review-supply-request/${id}`,
    data,
    addAuthorizationHeader(),
  );
};

export const updateSupplyRequest = (id, data) => {
  return api.put(
    `/update-supply-request/${id}`,
    data,
    addAuthorizationHeader(),
  );
};

export const getSupplyRequestHistory = (id, requestId) => {
  return api.get(
    `/supply-request/${requestId}/history/${id}`,
    addAuthorizationHeader(),
  );
};
