import { DotIcon } from 'assets/svg';
import { Container } from './Badge.style.js';

const Badge = props => {
  const { badgeColor, children, className = '', hexCode, ...rest } = props;

  function lightenHexColor(hex, factor) {
    if (!hex) return '';

    // Ensure the factor is within a valid range (0 to 1)
    factor = Math.min(1, Math.max(0, factor));

    // Parse the hex color to RGB
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Calculate the new RGB values
    r = Math.round(r + (255 - r) * factor);
    g = Math.round(g + (255 - g) * factor);
    b = Math.round(b + (255 - b) * factor);

    // Convert the RGB values back to hex
    const newHex = `#${((1 << 24) | (r << 16) | (g << 8) | b)
      .toString(16)
      .slice(1)}`;

    return newHex;
  }

  return (
    <Container
      {...rest}
      className={`${badgeColor} ${className}`}
      hexCode={hexCode}
      lighterBackground={lightenHexColor(hexCode, 0.8)}
    >
      <DotIcon />
      {children}
    </Container>
  );
};

export default Badge;
