import { createAsyncThunk } from '@reduxjs/toolkit';
import * as UserAPI from 'api/users';
import { showSnackBar } from 'store/snackbar/reducer';

const getProfile = createAsyncThunk(
  'user/getProfile',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await UserAPI.getProfile();

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ currentPassword, newPassword }, { rejectWithValue, dispatch }) => {
    try {
      const response = await UserAPI.changePassword({
        currentPassword,
        newPassword,
      });

      dispatch(
        showSnackBar({
          show: true,
          message: response.data.message,
          delay: 2000,
        }),
      );

      return
    } catch (err) {
      dispatch(
        showSnackBar({
          show: true,
          message: err.response.data.message,
          isError: true,
        }),
      );

      return 
    }
  },
);

const emailResetCode = createAsyncThunk(
  'user/emailResetCode',
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const response = await UserAPI.emailResetCode({
        email,
      });

      dispatch(
        showSnackBar({
          show: true,
          message: response.data.message,
          delay: 2000,
        }),
      );

      return email;
    } catch (err) {
      dispatch(
        showSnackBar({
          show: true,
          message: err.response.data.message,
          isError: true,
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);

const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async ({ email, resetCode, newPassword }, { rejectWithValue, dispatch }) => {
    try {
      const response = await UserAPI.resetPassword({
        email,
        resetCode,
        newPassword
      });

      dispatch(
        showSnackBar({
          show: true,
          message: response.data.message,
          delay: 2000,
        }),
      );

      return response.data;
    } catch (err) {
      dispatch(
        showSnackBar({
          show: true,
          message: err.response.data.message,
          isError: true,
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);

export { getProfile, changePassword, emailResetCode, resetPassword };
