import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ColorApi from 'api/color';
import { handleNetworkRequest } from 'utils/networkRequest';

const createColor = createAsyncThunk(
  'color/createColor',
  async (data, { dispatch }) => {
    return handleNetworkRequest(dispatch, () => ColorApi.createColor(data));
  },
);

const getColors = createAsyncThunk(
  'color/getColors',
  async (data, { dispatch }) => {
    return handleNetworkRequest(dispatch, () => ColorApi.getColors(data), {
      showPageLoader: false,
      showSuccessMessage: false,
    });
  },
);

export { createColor, getColors };
