import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleNetworkRequest } from 'utils/networkRequest';
import * as MaterialTransferAPI from 'api/materialTransfer';

const createMaterialTransferRequest = createAsyncThunk(
  'materialTransferRequest/createMaterialTransferRequest',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      MaterialTransferAPI.createMaterialTransferRequest(data),
    ),
);

const getAllMaterialTransferRequest = createAsyncThunk(
  'materialTransferRequest/getAllMaterialTransferRequest',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => MaterialTransferAPI.getAllMaterialTransferRequest(id),
      {
        showSuccessMessage: false,
      },
    ),
);

const updateMaterialTransferRequest = createAsyncThunk(
  'materialTransferRequest/updateMaterialTransferRequest',
  async ({ id, ...data }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      MaterialTransferAPI.updateMaterialTransferRequest(id, data),
    ),
);

// const getTransferRequestHistory = createAsyncThunk(
//   'materialTransferRequest/getMaterialTransferRequestHistory',
//   async ({ id }, { dispatch }) =>
//     handleNetworkRequest(
//       dispatch,
//       () => MaterialTransferAPI.getMaterialTransferRequestHistory(id),
//       {
//         showSuccessMessage: false,
//       },
//     ),
// );

export {
  createMaterialTransferRequest,
  getAllMaterialTransferRequest,
  updateMaterialTransferRequest,
//   getTransferRequestHistory,
};
