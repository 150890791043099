import styled from 'styled-components';
import { device } from 'constants';


export const Wrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 2px 4px 8px 1px #eeeeee;
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
  }

  .project-rows {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    flex-direction: column;
    margin-top: 1.5rem;

    @media ${device.isSmallDevice} {
      gap: 0rem;
      margin-top: 1.8rem;
    }

    .project-row {
      width: 100%;
      padding: 0.4rem;
      display: flex;
      gap: 0.8rem;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #eee;

      @media ${device.isSmallDevice} {
        gap: 1.5rem;
        padding: 0.9rem;
      }

      .color-code {
        padding: 0.6rem;
        border-radius: 0.8rem;

        @media ${device.isSmallDevice} {
          padding: 0.6rem;
        }

        &.grey {
          color: ${props => props.theme.greyText};
          background: rgba(90, 90, 90, 0.2);
        }

        &.blue {
          color: #17a2b8;
          background: rgba(23, 162, 184, 0.2);
        }

        &.orange {
          color: #ffa500;
          background: rgba(255, 165, 0, 0.2);
        }

        &.green {
          color: #008000;
          background: rgba(0, 128, 0, 0.2);
        }
      }

      p {
        color: ${props => props.theme.darkBlack};
      }
    }
  }
`;
