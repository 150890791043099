import { CircularProgress } from 'components';
import { useEffect, useState } from 'react';
import { Wrapper } from './DashboardProjects.style';

export default function DashboardProjects() {
  const data = ['not started', 'ongoing', 'on hold', 'completed'];
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setAmount(0);
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <h3 className="capitalize b-700">projects</h3>
      <div className="project-rows">
        {data.map((el, index) => (
          <div className="project-row" id={`row-${index}`} code={index}>
            {loading ? (
              <CircularProgress />
            ) : amount === 0 ? (
              <div
                className={
                  index === 0
                    ? 'color-code grey'
                    : index === 1
                    ? 'color-code blue'
                    : index === 2
                    ? 'color-code orange'
                    : index === 3
                    ? 'color-code green'
                    : 'color-code grey'
                }
              >
                <h4 className="b-700"> 0</h4>
              </div>
            ) : (
              '-'
            )}
            <div className="column">
              <p className="capitalize text-black b-500">{el}</p>
              <p className=" text-black b-500">Total projects {el}</p>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
