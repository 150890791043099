import styled from 'styled-components';

const redText = `
    &.red {
        color: #F00;
    }
`;

const orangeText = `
    &.orange {
        color: #ff6900;
    }
`;

const greenText = `
    &.green {
        color: green;
    }
`;

export const ShadowBoxContainer = styled.div`
  padding: 1.1428rem;
  border-radius: 0.5714rem;
  background: ${props => props.theme.white};
  box-shadow: 2px 4px 9px 0px rgba(90, 90, 90, 0.1);
  height: 6.2142rem;
  min-width: 9.3571rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top-text {
    color: ${props => props.theme.greyText};
    font-size: 1.1428rem;
    font-weight: 600;

    ${redText}
    ${orangeText}
    ${greenText}
  }

  .bottom-text {
    color: ${props => props.theme.greyText};
    font-size: 0.8571rem;
    font-weight: 600;

    ${redText}
    ${orangeText}
    ${greenText}
  }
`;
