import styled from 'styled-components';
import { getConditionalClass } from 'utils';

export const SelectWrapper = styled.div.attrs(props => ({
  className: `select-wrapper ${getConditionalClass(props.className)}`,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .MuiSelect-root {
    background: ${props => props.theme.milkyWhite};
    min-width: ${props => props.width || '2.5rem'};
    min-height: ${props => props.height || '2.8571rem'};
    border-radius: 3px;
    border: 0.07142rem solid #5a5a5a80;

    &:hover {
      background: none;
    }

    &.Mui-expanded {
      box-shadow: 0 0 0 1px ${props => props.theme.primaryColor};
      border: 0.07142rem solid ${props => props.theme.primaryColor};

      path {
        fill: ${props => props.theme.primaryColor};
      }
    }

    .MuiSelect-indicator {
      span.MuiCircularProgress-root {
        circle:first-of-type {
          stroke: ${props => props.theme.veryLightOrange};
        }
        circle:last-of-type {
          stroke: ${props => props.theme.primaryColor};
        }
      }
    }
  }
`;
