import { ArrowDownIcon } from "assets/svg";
import { graph } from "assets";
import { Wrapper } from "./Graph.syle";

export default function Graph() {
  return (
    <Wrapper>
      <div className="graph-header-flex">
        <h3 className="capitalize b-700">Income & Expenses</h3>
        <div className="flex align-center">
          <p>Today</p>
          <ArrowDownIcon />
        </div>
      </div>
      <img src={graph} alt="graph" />
    </Wrapper>
  );
}

