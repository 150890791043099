import styled from 'styled-components';
import { getConditionalClass } from 'utils';

export const TabsWrapper = styled.div.attrs(props => ({
  className: `tabs-wrapper ${getConditionalClass(props.className)}`,
}))`
  .MuiTabs-root {
    background: transparent;

    .MuiTabPanel-root {
      padding-left: 0;
      padding-right: 0;
    }

    .MuiTabList-root {
      background: transparent;

      & > a {
        text-decoration: none;
      }

      button.MuiTab-root {
        font-family: openSans;
        font-size: 1.2857rems;

        &:hover {
          background: transparent;
          font-weight: 600;
        }

        &.Mui-selected {
          background: transparent;
          font-weight: 600;

          &::after {
            background: ${props => props.theme.primaryColor};
            width: 53%;
          }
        }
      }
    }
  }
`;
