import { createAsyncThunk } from '@reduxjs/toolkit';
import * as AuthApi from 'api/auth';
import { showSnackBar } from 'store/snackbar/reducer';

const logIn = createAsyncThunk(
  'auth/logIn',
  async ({ email, password, remember }, { rejectWithValue, dispatch }) => {
    try {
      const response = await AuthApi.logIn({
        email,
        password,
      });

      dispatch(
        showSnackBar({
          show: true,
          message: response.data.message,
          delay: 2000,
        }),
      );

      return response.data;
    } catch (err) {
      dispatch(
        showSnackBar({
          show: true,
          message: err.response.data.message,
          isError: true,
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);

export { logIn };
