import styled from "styled-components";
import { device } from "constants";

export const Wrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 2px 4px 8px 1px #eeeeee;
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
    padding: 0;
    box-shadow: none;
  }

  .header-block {
    display: flex;
    width: 100%;
    gap: 1.2rem;
    flex-wrap: wrap;

    @media ${device.isSmallDevice} {
      justify-content: space-between;
      width: 100%;
      overflow: auto;
      flex-wrap: nowrap;
      margin-bottom: 1rem;
      padding-right: 5rem;
      padding-block: 0.5rem;
    }

    .header-item {
      background-color: transparent;
      min-height: 5.56rem;
      box-shadow: 2px 4px 8px 1px #eeeeee;
      border-radius: 1.2rem;
      width: 30%;
      padding-inline: 1rem;
      display: flex;
      gap: 0.4rem;
      align-items: flex-start;
      justify-content: center;
      cursor: pointer;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: auto;
        min-width: 10.56rem;
      }

      @media ${device.isSmallDevice} {
        width: min-content;
      }

      @media (max-width: 620px) {
        width: 100%;
      }

      svg {
        width: 1.23rem;
        height: 1.23rem;

        path {
          fill: ${(props) => props.theme.primaryColor};
        }
      }
    }
  }
`;
