import styled from 'styled-components';

export const Header = styled.div`
    background: rgba(255, 105, 0, 0.1);
    padding: 1rem;
    margin-block: 2rem 1rem;

    h6 {
        font-weight: 600;
    }
`;
