import styled, { css } from 'styled-components';
import { getConditionalClass } from 'utils';

export const defaultHeight = '48px';

export const ButtonWrapper = styled.div.attrs(props => ({
  className: `button-wrapper ${getConditionalClass(props.className)}`,
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    padding: 12px 16px;
    border-radius: 3px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    .MuiButton-startDecorator,
    .MuiMenuButton-startDecorator,
    .MuiButton-endDecorator,
    .MuiMenuButton-endDecorator {
      path {
        fill: ${props => props.theme.white};
      }
    }

    ${({ contain }) =>
      contain
        ? css`
            height: ${defaultHeight};
          `
        : css`
            width: ${props => props.width};
            height: ${props => props.height};
          `}
  }

  ${({ greyColor }) =>
    greyColor
      ? css`
      & > button {
        background: ${props => props.theme.greyText};
        color: ${props => props.theme.white};
        border: 0.07142rem solid #5a5a5a80;
    
    
        &:hover {
          box-shadow: 0px 4px 10px 0px rgba(255, 105, 0, 0.2);
          background: ${props => props.theme.greyText};
          color: ${props => props.theme.white};
        }
      }
    
      .MuiCircularProgress-root {
        circle:first-of-type {
          stroke: transparent;
        }
        circle:last-of-type {
          stroke: white;
        }
      }
    
      ${({ outlined }) =>
        outlined
          ? css`
              & > button {
                background: ${props => props.theme.white};
                color: ${props => props.theme.greyText};

                &:hover {
                  box-shadow: 2px 4px 10px 0px rgba(51, 51, 51, 0.2);
                  background: ${props => props.theme.white};
                  color: ${props => props.theme.greyText};
                }

                .MuiButton-startDecorator,
                .MuiMenuButton-startDecorator,
                .MuiMenuButton-endDecorator,
                .MuiButton-endDecorator {
                  path {
                    fill: ${props => props.theme.greyText};
                  }
                }
              }

              .MuiCircularProgress-root {
                circle:first-of-type {
                  stroke: ${props => props.theme.veryLightGrey};
                }
                circle:last-of-type {
                  stroke: ${props => props.theme.greyText};
                }
              }
            `
          : css``}
          }`
      : css` & > button {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.white};
    border: 0.07142rem solid ${props => props.theme.primaryColor};


    &:hover {
      box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.2);
      background: ${props => props.theme.primaryColor};
      color: ${props => props.theme.white};
    }
  }

  .MuiCircularProgress-root {
    circle:first-of-type {
      stroke: transparent;
    }
    circle:last-of-type {
      stroke: white;
    }
  }

  ${({ outlined }) =>
    outlined
      ? css`
          & > button {
            background: ${props => props.theme.white};
            color: ${props => props.theme.primaryColor};

            &:hover {
              box-shadow: 2px 4px 10px 0px rgba(255, 105, 0, 0.2);
              background: ${props => props.theme.white};
              color: ${props => props.theme.primaryColor};
            }

            .MuiButton-startDecorator,
            .MuiMenuButton-startDecorator,
            .MuiMenuButton-endDecorator,
            .MuiButton-endDecorator {
              path {
                fill: ${props => props.theme.primaryColor};
              }
            }
          }

          .MuiCircularProgress-root {
            circle:first-of-type {
              stroke: ${props => props.theme.veryLightOrange};
            }
            circle:last-of-type {
              stroke: ${props => props.theme.primaryColor};
            }
          }
        `
      : css``}

  }`}
`;
