import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ProjectApi from 'api/projects';
import { handleNetworkRequest } from 'utils/networkRequest';

const getAllProjects = createAsyncThunk(
  'projects/getAllProjects',
  async (data, { getState, dispatch }) => {
    const { page } = data || { page: 0 };

    const {
      projects: { tableParams },
    } = getState();

    const query = { ...tableParams };

    if (page) {
      query.page = page;
    }

    return handleNetworkRequest(
      dispatch,
      () => ProjectApi.getAllProjects(query),
      {
        showSuccessMessage: false,
      },
    );
  },
);

const createProject = createAsyncThunk(
  'projects/createProject',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.createProject(data)),
);

const updateProject = createAsyncThunk(
  'projects/updateProject',
  async ({ id, data }, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.updateProject(id, data)),
);

const updateProjectStatus = createAsyncThunk(
  'projects/updateProjectStatus',
  async (data, { dispatch, getState }) => {
    const {
      projects: {
        activeProject: { id },
      },
    } = getState();

    return handleNetworkRequest(dispatch, () =>
      ProjectApi.updateProjectStatus(id, data),
    );
  },
);

const getProjectById = createAsyncThunk(
  'projects/getProjectById',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getProjectById(id), {
      showSuccessMessage: false,
    }),
);

const getProjectsCodes = createAsyncThunk(
  'projects/getProjectsCodes',
  async (_args, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getProjectsCodes(), {
      showSuccessMessage: false,
      showPageLoader: false,
    }),
);

const createProjectStatus = createAsyncThunk(
  'projects/createProjectStatus',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.createProjectStatus(data)),
);

const getProjectStatuses = createAsyncThunk(
  'projects/getProjectStatuses',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getProjectStatuses(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

const createFurnitureType = createAsyncThunk(
  'projects/createFurnitureType',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ProjectApi.createFurnitureType({ name, description }),
    ),
);

const createConcreteType = createAsyncThunk(
  'projects/createConcreteType',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ProjectApi.createConcreteType({ name, description }),
    ),
);

const createBuildingPurpose = createAsyncThunk(
  'projects/createBuildingPurpose',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ProjectApi.createBuildingPurpose({ name, description }),
    ),
);

const createBuildingUnitType = createAsyncThunk(
  'projects/createBuildingUnitType',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ProjectApi.createBuildingUnitType({ name, description }),
    ),
);

const getFurnitureTypes = createAsyncThunk(
  'projects/getFurnitureTypes',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getFurnitureTypes(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

const getConcreteTypes = createAsyncThunk(
  'projects/getConcreteTypes',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getConcreteTypes(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

const getBuildingPurposes = createAsyncThunk(
  'projects/getBuildingPurposes',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getBuildingPurposes(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

const getBuildingUnitTypes = createAsyncThunk(
  'projects/getBuildingUnitTypes',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getBuildingUnitTypes(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

//Other rooms
const createOtherRooms = createAsyncThunk(
  'projects/createOtherRooms',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      ProjectApi.createOtherRooms({ name, description }),
    ),
);

const getOtherRooms = createAsyncThunk(
  'projects/getOtherRooms',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(dispatch, () => ProjectApi.getOtherRooms(), {
      showPageLoader: false,
      showSuccessMessage: false,
    }),
);

export {
  getAllProjects,
  getProjectById,
  createProject,
  updateProject,
  getProjectsCodes,
  createProjectStatus,
  getProjectStatuses,
  createConcreteType,
  createFurnitureType,
  createBuildingPurpose,
  createBuildingUnitType,
  getBuildingPurposes,
  getConcreteTypes,
  getFurnitureTypes,
  getBuildingUnitTypes,
  updateProjectStatus,
  createOtherRooms,
  getOtherRooms
};
