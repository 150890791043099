import { addAuthorizationHeader, api } from './axios';
import { queryBuilder } from 'utils';
import { createCategory, getCategoryByType, CATEGORY_TYPES } from './category';

export const getAllProjects = ({ query }) => {
  return api.get(
    `/projects${queryBuilder(query, ['page', 'search', 'perPage', 'type'])}`,
    addAuthorizationHeader(),
  );
};

export const getOngoingProjects = ({ query }) => {
  const updatedQuery = {
    ...query,
    type: CATEGORY_TYPES.ONGOING_PROJECT_CATEGORY,
  };

  return api.get(
    `/projects${queryBuilder(updatedQuery, [
      'page',
      'search',
      'perPage',
      'type',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getCompletedProjects = ({ query }) => {
  const updatedQuery = {
    ...query,
    type: CATEGORY_TYPES.COMPLETED_PROJECT_CATEGORY,
  };
  return api.get(
    `/projects${queryBuilder(updatedQuery, [
      'page',
      'search',
      'perPage',
      'type',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getProjectById = id => {
  return api.get(`/get-project-by-id/${id}`, addAuthorizationHeader());
};

export const createProject = data => {
  return api.post('/create-project', data, addAuthorizationHeader());
};

export const updateProject = (id, data) => {
  return api.put(`update-project/${id}`, data, addAuthorizationHeader());
};

export const updateProjectStatus = (id, data) => {
  return api.put(`update-project-status/${id}`, data, addAuthorizationHeader());
};

export const getProjectsCodes = id => {
  return api.get(`/projects-codes`, addAuthorizationHeader());
};

export const createProjectStatus = data => {
  return createCategory(data);
};

export const getProjectStatuses = () => {
  return getCategoryByType([
    CATEGORY_TYPES.ONGOING_PROJECT_CATEGORY,
    CATEGORY_TYPES.COMPLETED_PROJECT_CATEGORY,
  ]);
};

export const createFurnitureType = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.FURNITURE_TYPE };
  return createCategory(updatedData);
};

export const createConcreteType = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.CONCRETE_TYPE };
  return createCategory(updatedData);
};

export const createBuildingPurpose = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.BUILDING_PURPOSE };
  return createCategory(updatedData);
};

export const createBuildingUnitType = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.BUILDING_UNIT_TYPE };
  return createCategory(updatedData);
};

export const getFurnitureTypes = () => {
  return getCategoryByType([CATEGORY_TYPES.FURNITURE_TYPE]);
};

export const getConcreteTypes = () => {
  return getCategoryByType([CATEGORY_TYPES.CONCRETE_TYPE]);
};

export const getBuildingPurposes = () => {
  return getCategoryByType([CATEGORY_TYPES.BUILDING_PURPOSE]);
};

export const getBuildingUnitTypes = () => {
  return getCategoryByType([CATEGORY_TYPES.BUILDING_UNIT_TYPE]);
};

//OTHER ROOMS
export const createOtherRooms = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.OTHER_ROOMS };
  return createCategory(updatedData);
};

export const getOtherRooms = () => {
  return getCategoryByType([CATEGORY_TYPES.OTHER_ROOMS]);
};
