import styled from 'styled-components';
import { getConditionalClass } from 'utils';

export const Wrapper = styled.div.attrs(props => ({
  className: `review-wrapper ${getConditionalClass(props.className)}`,
}))`
  label {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 1rem;

    &.required::after {
      content: '*';
      color: red;
      margin-left: 0.3rem;
    }
  }

  .btn-row {
    margin-top: 2.8571rem;
  }
`;
