import { api, addAuthorizationHeader } from './axios';

export const CATEGORY_TYPES = {
  PROJECT_CATEGORY: 'project_category',
  STAFF_EMPLOYMENT_TYPE: 'staff_employment_type',
  STAFF_POSITION: 'staff_position',
  WAREHOUSE_ITEM_CATEGORY: 'master_list_category',
  EXPENSE_REQUEST_CATEGORY: 'expense_request',
  ONGOING_PROJECT_CATEGORY: 'ongoing_project',
  COMPLETED_PROJECT_CATEGORY: 'completed_project',
  FURNITURE_TYPE: 'furniture_type',
  CONCRETE_TYPE: 'concrete_type',
  BUILDING_PURPOSE: 'building_purpose',
  BUILDING_UNIT_TYPE: 'building_unit_type',
  OTHER_ROOMS: 'other_rooms'
};

export const createCategory = data => {
  return api.post('/create-category', data, addAuthorizationHeader());
};

export const getCategoryByType = typeArray => {
  return api.get(
    `/get-category-by-type?type=${typeArray.join(',')}`,
    addAuthorizationHeader(),
  );
};
