import { NavLink, useLocation } from 'react-router-dom';
import { checkActiveLink, cn } from 'utils';
import { LogoIcon } from 'assets/svg';
import { navItems } from 'constants';
import SubMenu from 'layout/LoggedInLayout/SideNav/SubMenu';
import { MdLogout } from 'react-icons/md';

const UpdatedSidebar = () => {
  const location = useLocation();

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="fixed left-0 top-0 h-screen bg-navy flex flex-col space-y-5">
      <div className="hidden xl:block w-[280px] relative p-4 overflow-y-auto flex-col space-y-5">
        <div className="flex items-center justify-center mb-10">
          <LogoIcon />
        </div>

        <div className="space-y-3">
          {navItems.map((item, index) =>
            item.hasSubmenu ? (
              <SubMenu key={index} {...item} />
            ) : (
              <NavLink
                to={item.url}
                key={`nav-item-${index}`}
                className={cn(
                  'text-base px-6 space-x-3 py-2 rounded-lg text-white hover:bg-white hover:!text-orange flex hover:font-semibold items-center justify-start w-full',
                  {
                    'bg-white text-orange font-semibold': checkActiveLink(
                      location,
                      item.url,
                      item.exact,
                    ), // if checkActiveLink is true apply
                  },
                )}
              >
                <div>{item.icon}</div>
                <p>{item.title}</p>
              </NavLink>
            ),
          )}
        </div>
      </div>

      <div className="w-full p-4">
        <div
          className="space-x-3 cursor-pointer my-2 text-base px-6 py-2 rounded-lg text-white hover:bg-white hover:!text-orange hover:font-semibold flex items-center"
          onClick={logOut}
        >
          <MdLogout className="w-8 h-8" />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default UpdatedSidebar;
