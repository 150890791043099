import * as React from 'react';
import CheckboxElement from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import { CheckboxWrapper } from './Checkbox.style';

const Checkbox = props => {

  return (
    <CheckboxWrapper
      width={props.width}
      height={props.height}
      className={props.className}
      loading={props.loading}
    >
      <FormControl error={props.error || props.errorMessage}>
        {/* Label  */}
        {props.label && <FormLabel>{props.label}</FormLabel>}

        {/* Input  */}
        <CheckboxElement
          error={props.error}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
          type={props.type}
          sx={{
            '&.Mui-checked': {
              color: 'red'
            }
          }}
        />

        {/* Error Message  */}
        {props.errorMessage && (
          <FormHelperText>{props.errorMessage}</FormHelperText>
        )}
      </FormControl>
    </CheckboxWrapper>
  );
};

export default Checkbox;
