import { createGlobalStyle } from 'styled-components';

export const DropdownOptionsGlobalStyle = createGlobalStyle`
  ul.MuiMenu-root.MuiPopper-root {
    border: 1px solid rgba(90, 90, 90, 0.2);
    border-radius: 3px;

    a {
      color: inherit;
      text-decoration: none;
    }

    li {
      min-width: 8rem;
      &.MuiOption-highlighted {
        background: ${props => props.theme.veryLightOrange};
      }

      &:hover {
        background: ${props => props.theme.veryLightOrange};
      }
    }
    
  }
`;
