import styled from 'styled-components';

export const ImageBlock = styled.main.attrs(() => ({
  className: 'image-block',
}))`
  display: flex;
  align-items: center;
  margin-left: 11.4285rem;

  .img-frame {
    border: 0.3571rem solid ${props => props.theme.primaryColor};
    display: inline-flex;
    padding: 3rem 0;

    img {
      position: relative;
      left: calc(-3.3571rem);
    }
  }
`;

export const Layout = styled.main`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  & > .wrapper {
    display: flex;

    > .outlet {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  //DO NOT DELETE
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr;
  // grid-template-areas: 'form-block image-block';

  // .form-block {
  //   grid-area: form-block;
  // }

  // .image-block {
  //   grid-area: image-block;
  // }

  //DO NOT DELETE
`;
