import styled from 'styled-components';
import { device } from 'constants';

export const Wrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 2px 4px 8px 1px #eeeeee;
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
  }

  .data-rows {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    flex-direction: column;

    .data-row {
      width: 100%;
      padding: 0.3rem;
      display: flex;
      gap: 0.8rem;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #eee;

      p {
        color: ${props => props.theme.textBlack};
      }
    }
  }
`;
