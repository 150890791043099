import { lazy } from 'react';
import { urlBuilder } from 'utils';

const Warehouse = lazy(() => import('pages/Warehouse/Dashboard'));
const ProductList = lazy(() => import('pages/Warehouse/ProductList'));

// Expense Request
const ExpenseRequestDetails = lazy(() =>
  import('pages/ExpenseRequest/ExpenseRequestDetails'),
);

const ExpenseRequestHistory = lazy(() =>
  import('pages/ExpenseRequest/ExpenseRequestHistory'),
);

// Supply Request
const SupplyRequestsDetails = lazy(() =>
  import('pages/SupplyRequest/SupplyRequestsDetails'),
);

const SupplyRequestHistory = lazy(() =>
  import('pages/SupplyRequest/SupplyRequestHistory'),
);

// Purchase Order
const PurchaseOrderDetails = lazy(() =>
  import('pages/PurchaseOrder/PurchaseOrderDetails'),
);

const PurchaseOrderHistory = lazy(() =>
  import('pages/PurchaseOrder/PurchaseOrderHistory'),
);

export const WAREHOUSE_ROUTES_PATHS = {
  DASHBOARD_PAGE: ({
    isExpenseRequests,
    isSupplyRequests,
    isPurchaseOrders,
    isMaterialTransfers,
  } = {}) => {
    let url = '/warehouse-activities';

    if (isExpenseRequests) {
      url += '?tab=expense-requests';
    } else if (isSupplyRequests) {
      url += '?tab=supply-requests';
    } else if (isPurchaseOrders) {
      url += '?tab=purchase-orders';
    } else if (isMaterialTransfers) {
      url += '?tab=material-transfers';
    }

    return url;
  },
  EXPENSE_REQUEST_DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder([
      'warehouse-activities',
      'expense-request',
      id ? id : ':id',
      'details',
    ]),
  EXPENSE_REQUEST_HISTORY_DETAILS_PAGE: ({ id, requestId } = {}) =>
    urlBuilder([
      'warehouse-activities',
      'expense-request',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
  SUPPLY_REQUEST_DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder([
      'warehouse-activities',
      'supply-request',
      id ? id : ':id',
      'details',
    ]),
  SUPPLY_REQUEST_HISTORY_DETAILS_PAGE: ({ id, requestId } = {}) =>
    urlBuilder([
      'warehouse-activities',
      'supply-request',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
  PURCHASE_ORDER_DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder([
      'warehouse-activities',
      'purchase-order',
      id ? id : ':id',
      'details',
    ]),
  PURCHASE_ORDER_HISTORY_DETAILS_PAGE: ({ id, requestId } = {}) =>
    urlBuilder([
      'warehouse-activities',
      'purchase-order',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
};

const title = 'Warehouse Activities';

const WarehouseRoutes = () => [
  { path: '/warehouse-activities', element: <Warehouse />, protected: true },
  { path: '/warehouse/products', element: <ProductList />, protected: true },
  {
    path: WAREHOUSE_ROUTES_PATHS.EXPENSE_REQUEST_DETAILS_PAGE(),
    element: (
      <ExpenseRequestDetails
        breadCrumb={({ requestCode }) => [
          {
            title,
            url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE({
              isExpenseRequests: true,
            }),
          },
          {
            title: requestCode || 'Expense request details',
          },
        ]}
        reviewable={true}
        historyPageurlFunc={({ id, requestId }) =>
          WAREHOUSE_ROUTES_PATHS.EXPENSE_REQUEST_HISTORY_DETAILS_PAGE({
            id,
            requestId,
          })
        }
      />
    ),
    index: true,
    protected: true,
  },
  {
    path: WAREHOUSE_ROUTES_PATHS.EXPENSE_REQUEST_HISTORY_DETAILS_PAGE(),
    element: (
      <ExpenseRequestHistory
        breadCrumb={({ requestCode, requestId }) => {
          const breadCrumb = [
            {
              title,
              url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE({
                isExpenseRequests: true,
              }),
            },
            {
              title: 'Expense request history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(1, 0, {
              title: requestCode,
              url: WAREHOUSE_ROUTES_PATHS.EXPENSE_REQUEST_DETAILS_PAGE({
                id: requestId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
  {
    path: WAREHOUSE_ROUTES_PATHS.SUPPLY_REQUEST_DETAILS_PAGE(),
    element: (
      <SupplyRequestsDetails
        breadCrumb={({ requestCode }) => [
          {
            title,
            url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE({
              isSupplyRequests: true,
            }),
          },
          {
            title: requestCode || 'Supply request detail',
          },
        ]}
        reviewable={true}
        historyPageurlFunc={({ id, requestId }) =>
          WAREHOUSE_ROUTES_PATHS.SUPPLY_REQUEST_HISTORY_DETAILS_PAGE({
            id,
            requestId,
          })
        }
      />
    ),
    index: true,
    protected: true,
  },
  {
    path: WAREHOUSE_ROUTES_PATHS.SUPPLY_REQUEST_HISTORY_DETAILS_PAGE(),
    element: (
      <SupplyRequestHistory
        breadCrumb={({ requestCode, requestId }) => {
          const breadCrumb = [
            {
              title,
              url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE({
                isSupplyRequests: true,
              }),
            },
            {
              title: 'Supply request history details',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(1, 0, {
              title: requestCode,
              url: WAREHOUSE_ROUTES_PATHS.SUPPLY_REQUEST_DETAILS_PAGE({
                id: requestId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
  {
    path: WAREHOUSE_ROUTES_PATHS.PURCHASE_ORDER_DETAILS_PAGE(),
    element: (
      <PurchaseOrderDetails
        breadCrumb={({ requestCode }) => [
          {
            title,
            url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE({
              isPurchaseOrders: true,
            }),
          },
          {
            title: requestCode || 'Purchase order details',
          },
        ]}
        reviewable={true}
        historyPageurlFunc={({ id, requestId }) =>
          WAREHOUSE_ROUTES_PATHS.PURCHASE_ORDER_HISTORY_DETAILS_PAGE({
            id,
            requestId,
          })
        }
      />
    ),
    index: true,
    protected: true,
  },
  {
    path: WAREHOUSE_ROUTES_PATHS.PURCHASE_ORDER_HISTORY_DETAILS_PAGE(),
    element: (
      <PurchaseOrderHistory
        breadCrumb={({ requestCode, requestId }) => {
          const breadCrumb = [
            {
              title,
              url: WAREHOUSE_ROUTES_PATHS.DASHBOARD_PAGE({
                isPurchaseOrders: true,
              }),
            },
            {
              title: 'Purchase order history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(1, 0, {
              title: requestCode,
              url: WAREHOUSE_ROUTES_PATHS.PURCHASE_ORDER_DETAILS_PAGE({
                id: requestId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
];

export default WarehouseRoutes;
