import { PaginationWrapper } from './Pagination.style';
import PaginationElement from '@mui/material/Pagination';
import { initPaginationData } from 'utils';

const Pagination = props => {
  const data = props.config || initPaginationData();

  const handlePageChange = (event, page) => {
    props.onChangePage({ page });
  };

  const range = !data.to && !data.from ? 0 : `${data.from} - ${data.to}`;

  return (
    <PaginationWrapper>
      <span>
        showing {range} of {data.total}
      </span>
      <PaginationElement
        count={data.total_pages}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
