import { lazy } from 'react';
import { urlBuilder } from 'utils';

const Requests = lazy(() => import('pages/Requests'));

// Expense Request
const ExpenseRequestDetails = lazy(() =>
  import('pages/ExpenseRequest/ExpenseRequestDetails'),
);

const ExpenseRequestHistory = lazy(() =>
  import('pages/ExpenseRequest/ExpenseRequestHistory'),
);

// Supply Request
const SupplyRequestsDetails = lazy(() =>
  import('pages/SupplyRequest/SupplyRequestsDetails'),
);

const SupplyRequestHistory = lazy(() =>
  import('pages/SupplyRequest/SupplyRequestHistory'),
);

const MaterialTransferRequest = lazy(() =>
  import('pages/MaterialTransfer/MaterialTransferRequest'),
);

// Purchase Order
const PurchaseOrderDetails = lazy(() =>
  import('pages/PurchaseOrder/PurchaseOrderDetails'),
);

const PurchaseOrderHistory = lazy(() =>
  import('pages/PurchaseOrder/PurchaseOrderHistory'),
);

export const REQUEST_ROUTES_PATHS = {
  DASHBOARD_PAGE: ({
    isExpenseRequests,
    isSupplyRequests,
    isPurchaseOrders,
    isMaterialTransfers,
  } = {}) => {
    let url = '/requests';

    if (isExpenseRequests) {
      url += '?tab=expense-requests';
    } else if (isSupplyRequests) {
      url += '?tab=supply-requests';
    } else if (isPurchaseOrders) {
      url += '?tab=purchase-orders';
    } else if (isMaterialTransfers) {
      url += '?tab=material-transfers';
    }

    return url;
  },
  EXPENSE_REQUEST_DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder(['requests', 'expense-request', id ? id : ':id', 'details']),
  EXPENSE_REQUEST_HISTORY_DETAILS_PAGE: ({ id, requestId } = {}) =>
    urlBuilder([
      'requests',
      'expense-request',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
  SUPPLY_REQUEST_DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder(['requests', 'supply-request', id ? id : ':id', 'details']),
  SUPPLY_REQUEST_HISTORY_DETAILS_PAGE: ({ id, requestId } = {}) =>
    urlBuilder([
      'requests',
      'supply-request',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
  PURCHASE_ORDER_DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder(['requests', 'purchase-order', id ? id : ':id', 'details']),
  PURCHASE_ORDER_HISTORY_DETAILS_PAGE: ({ id, requestId } = {}) =>
    urlBuilder([
      'requests',
      'purchase-order',
      requestId ? requestId : ':requestId',
      'history',
      id ? id : ':id',
      'details',
    ]),
};

const title = 'Requests';

const RequestsRoutes = () => [
  {
    path: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE(),
    element: <Requests />,
    index: true,
    protected: true,
  },
  {
    path: REQUEST_ROUTES_PATHS.EXPENSE_REQUEST_DETAILS_PAGE(),
    element: (
      <ExpenseRequestDetails
        breadCrumb={({ requestCode }) => [
          {
            title,
            url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE({
              isExpenseRequests: true,
            }),
          },
          {
            title: requestCode || 'Expense request details',
          },
        ]}
        reviewable={true}
        historyPageurlFunc={({ id, requestId }) =>
          REQUEST_ROUTES_PATHS.EXPENSE_REQUEST_HISTORY_DETAILS_PAGE({
            id,
            requestId,
          })
        }
      />
    ),
    index: true,
    protected: true,
  },
  {
    path: REQUEST_ROUTES_PATHS.EXPENSE_REQUEST_HISTORY_DETAILS_PAGE(),
    element: (
      <ExpenseRequestHistory
        breadCrumb={({ requestCode, requestId }) => {
          const breadCrumb = [
            {
              title,
              url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE({
                isExpenseRequests: true,
              }),
            },
            {
              title: 'Expense request history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(1, 0, {
              title: requestCode,
              url: REQUEST_ROUTES_PATHS.EXPENSE_REQUEST_DETAILS_PAGE({
                id: requestId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
  {
    path: REQUEST_ROUTES_PATHS.SUPPLY_REQUEST_DETAILS_PAGE(),
    element: (
      <SupplyRequestsDetails
        breadCrumb={({ requestCode }) => [
          {
            title,
            url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE({
              isSupplyRequests: true,
            }),
          },
          {
            title: requestCode || 'Supply request detail',
          },
        ]}
        reviewable={true}
        historyPageurlFunc={({ id, requestId }) =>
          REQUEST_ROUTES_PATHS.SUPPLY_REQUEST_HISTORY_DETAILS_PAGE({
            id,
            requestId,
          })
        }
      />
    ),
    index: true,
    protected: true,
  },
  {
    path: REQUEST_ROUTES_PATHS.SUPPLY_REQUEST_HISTORY_DETAILS_PAGE(),
    element: (
      <SupplyRequestHistory
        breadCrumb={({ requestCode, requestId }) => {
          const breadCrumb = [
            {
              title,
              url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE({
                isSupplyRequests: true,
              }),
            },
            {
              title: 'Supply request history details',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(1, 0, {
              title: requestCode,
              url: REQUEST_ROUTES_PATHS.SUPPLY_REQUEST_DETAILS_PAGE({
                id: requestId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },
  {
    path: REQUEST_ROUTES_PATHS.PURCHASE_ORDER_DETAILS_PAGE(),
    element: (
      <PurchaseOrderDetails
        breadCrumb={({ requestCode }) => [
          {
            title,
            url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE({
              isPurchaseOrders: true,
            }),
          },
          {
            title: requestCode || 'Purchase order details',
          },
        ]}
        reviewable={true}
        historyPageurlFunc={({ id, requestId }) =>
          REQUEST_ROUTES_PATHS.PURCHASE_ORDER_HISTORY_DETAILS_PAGE({
            id,
            requestId,
          })
        }
      />
    ),
    index: true,
    protected: true,
  },
  {
    path: REQUEST_ROUTES_PATHS.PURCHASE_ORDER_HISTORY_DETAILS_PAGE(),
    element: (
      <PurchaseOrderHistory
        breadCrumb={({ requestCode, requestId }) => {
          const breadCrumb = [
            {
              title,
              url: REQUEST_ROUTES_PATHS.DASHBOARD_PAGE({
                isPurchaseOrders: true,
              }),
            },
            {
              title: 'Purchase order history detail',
            },
          ];

          if (requestCode) {
            breadCrumb.splice(1, 0, {
              title: requestCode,
              url: REQUEST_ROUTES_PATHS.PURCHASE_ORDER_DETAILS_PAGE({
                id: requestId,
              }),
            });
          }

          return breadCrumb;
        }}
      />
    ),
    protected: true,
  },

  { path: '/requests/material-transfer-request', element: <MaterialTransferRequest /> },
];

export default RequestsRoutes;
