import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleNetworkRequest } from 'utils/networkRequest';
import * as SupplyRequestApi from 'api/supplyRequests';

const createSupplyRequest = createAsyncThunk(
  'supplyRequest/createSupplyRequest',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      SupplyRequestApi.createSupplyRequest(data),
    ),
);

const getSupplyRequest = createAsyncThunk(
  'supplyRequest/getSupplyRequest',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => SupplyRequestApi.getSupplyRequest(id),
      {
        showSuccessMessage: false,
      },
    ),
);

const reviewSupplyRequest = createAsyncThunk(
  'supplyRequest/reviewSupplyRequest',
  async ({ id, comment, status }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      SupplyRequestApi.reviewSupplyRequest(id, { comment, status }),
    ),
);

const updateSupplyRequest = createAsyncThunk(
  'supplyRequest/updateSupplyRequest',
  async ({ id, ...data }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      SupplyRequestApi.updateSupplyRequest(id, data),
    ),
);

const getSupplyRequestHistory = createAsyncThunk(
  'supplyRequest/getSupplyRequestHistory',
  async ({ id, requestId }, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => SupplyRequestApi.getSupplyRequestHistory(id, requestId),
      {
        showSuccessMessage: false,
      },
    ),
);

export {
  createSupplyRequest,
  getSupplyRequest,
  reviewSupplyRequest,
  updateSupplyRequest,
  getSupplyRequestHistory,
};
