import ButtonElement from '@mui/joy/Button';
import { ButtonWrapper, defaultHeight } from './Button.style';
import CircularProgress from 'components/CircularProgress';

const Button = props => {
  const endDecorator = props.loading ? (
    <CircularProgress />
  ) : (
    props.endDecorator
  );

  return (
    <ButtonWrapper
      className={props.className}
      width={getWidth(props.width, props.size)}
      height={getHeight(props.height)}
      loading={props.loading}
      outlined={props.outlined}
      contain={props.contain}
      greyColor={props.greyColor}
    >
      <ButtonElement
        disabled={props.disabled || (props.loading && true)}
        variant="plain"
        startDecorator={props.startDecorator}
        endDecorator={endDecorator}
        type={props.type || 'button'}
        onClick={props.onClick}
        style={props.style || {}}
      >
        {props.children}
      </ButtonElement>
    </ButtonWrapper>
  );
};

export const getWidth = (width, size) => {
  if (width) return width;

  const defaultWidth = '150px';

  switch (size) {
    case 'normal':
      return defaultWidth;
    case 'small':
      return '120px';
    case 'large':
      return '240px';
    case 'extra-large':
      return '300px'
    default:
      return defaultWidth;
  }
};

export const getHeight = height => (height ? height : defaultHeight);

export default Button;
