import { Table } from 'components';
import { TableConfig } from './TableConfig';

const RequestHistory = ({ history, historyPageurl }) => {
  return (
    <Table
      config={TableConfig(history, historyPageurl)}
      pageNumber={0}
      perPage={0}
    />
  );
};

export default RequestHistory;
