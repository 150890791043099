import { createSlice } from '@reduxjs/toolkit';
import {
  getAllMaterialTransferRequest,
  updateMaterialTransferRequest,
} from './actions';

const initialState = {
  activeTransferRequest: null,
  activeTransferRequestHistory: null,
};

const transferRequestSlice = createSlice({
  name: 'transferRequest',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getTransferRequest
    builder.addCase(getAllMaterialTransferRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeTransferRequestHistory = data;
    });


    // updateTransferRequest
    builder.addCase(updateMaterialTransferRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeTransferRequest = data;
    });

    // getTransferRequestHistory
    // builder.addCase(getMaterialTransferRequest.fulfilled, (state, action) => {
    //   const data = action.payload.data;
    //   state.activeTransferRequestHistory = data;
    // });
  },
});

export const {} = transferRequestSlice.actions;

export default transferRequestSlice.reducer;
