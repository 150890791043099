import { lazy } from 'react';
import { urlBuilder } from 'utils';

const StaffDashboard = lazy(() => import('pages/Staff/Dashboard'));
const StaffDetails = lazy(() => import('pages/Staff/StaffDetails'));

export const STAFF_ROUTES_PATHS = {
  DASHBOARD_PAGE: () => '/staff',
  DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder(['staff', id ? id : ':id', 'details']),
};

const StaffRoutes = () => [
  {
    path: STAFF_ROUTES_PATHS.DASHBOARD_PAGE(),
    element: <StaffDashboard />,
    protected: true,
  },
  {
    path: STAFF_ROUTES_PATHS.DETAILS_PAGE(),
    element: <StaffDetails />,
    protected: true,
  },
];

export default StaffRoutes;
