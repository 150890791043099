import { useState, useEffect } from 'react';
import { CircularProgress } from 'components';
import { Wrapper, FieldHeader, Header } from './RecentTransactions.style';

export default function RecentTransactions() {
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(true);
  const data = [
    {
      type: 'income',
      source: 'project balance',
    },
    {
      type: 'income',
      source: 'general account',
    },
    {
      type: 'expenses',
      source: 'general account',
    },
    {
      type: 'income',
      source: 'project balance',
    },
    {
      type: 'expenses',
      source: 'project balance',
    },
    {
      type: 'income',
      source: 'general account',
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setTransactions(data);
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <Header>
        <h3 className="capitalize b-700">Recent transactions</h3>
      </Header>

      <FieldHeader>
        <div className="field-titles">
          <p className="t-sm">ID</p>
          <p className="capitalize t-sm">Transaction type</p>
          <p className="capitalize t-sm">source / destination</p>
          <p className="capitalize t-sm">amount</p>
          <p className="capitalize t-sm">date</p>
        </div>
        {transactions?.map((transaction, key) => (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <li className="data-row" key={`data-row-${key}`}>
                <p className="t-sm">100</p>
                <p className="capitalize t-sm">{transaction.type}</p>
                <p className="capitalize t-sm">{transaction.source}</p>
                <p className="t-sm"> &#8358; 0</p>
                <p className="t-sm">12 July 2023</p>
              </li>
            )}
          </>
        ))}
      </FieldHeader>
    </Wrapper>
  );
}