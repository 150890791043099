import styled from 'styled-components';
import { device } from 'constants';

export const Wrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 2px 4px 8px 1px #eeeeee;
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;
`;

export const FieldHeader = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  cursor: pointer;

  .field-titles {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    justify-content: space-between;
    padding: 0.8rem;
    background: rgba(255, 105, 0, 0.1);

    @media ${device.isSmallDevice} {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 991px) {
      padding: 1rem;
    }

    p {
      font-weight: 600;
      min-width: 6.3rem;
      max-width: 6.3rem;
      margin-right: 1em;

      @media ${device.isSmallDevice} {
        max-width: none;
      }

      @media (max-width: 520px) {
        font-size: 0.8rem;
      }
    }
  }

  .data-row {
    display: flex;
    padding: 0.8rem;
    border-radius: 0.4rem;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #eee;

    @media (max-width: 991px) {
      padding: 0.75rem;
    }

    @media ${device.isSmallDevice} {
      // width: 100%;
      display: flex;
      justify-content: space-between;
    }

    p {
      text-transform: capitalize;
      min-width: 6.3rem;
      max-width: 6.3rem;
      margin-right: 1em;
      text-overflow: ellipsis;
      overflow: hidden;

      @media ${device.isSmallDevice} {
        max-width: none;
      }

      @media (max-width: 520px) {
        font-size: 0.7rem;
      }
    }
  }
`;
