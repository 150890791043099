import { api, addAuthorizationHeader } from './axios';
import { CATEGORY_TYPES, createCategory, getCategoryByType } from './category';
import { queryBuilder } from 'utils';

export const createWarehouseItemCategory = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.WAREHOUSE_ITEM_CATEGORY };
  return createCategory(updatedData);
};

export const getWarehouseItemCategories = () => {
  return getCategoryByType([CATEGORY_TYPES.WAREHOUSE_ITEM_CATEGORY]);
};

export const createWarehouseItem = data => {
  return api.post('/create-warehouse-item', data, addAuthorizationHeader());
};

export const getAllMainWarehouseItems = ({ query }) => {
  return api.get(
    `/get-inventory-items/1${queryBuilder(query, [
      'page',
      'search',
      'category',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getWarehouseItemsName = () => {
  return api.get(`/warehouse-items-name`, addAuthorizationHeader());
};

export const getMasterListitems = () => {
  return api.get(`/master-list-items`, addAuthorizationHeader());
};
