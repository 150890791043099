import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'components';
import { SearchIcon } from 'assets/svg';

const SearchBar = ({
  onChange,
  onSearch,
  value = '',
  width = '21.42857rem',
  height,
  className = '',
}) => {
  const [searchTimer, setSearchTimer] = useState(null);
  const dispatch = useDispatch();

  const search = () => dispatch(onSearch());

  const handleInputChange = e => {
    const value = e.target.value;
    dispatch(onChange({ key: 'search', value }));

    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    setSearchTimer(setTimeout(search, 500));
  };

  return (
    <Input
      startDecorator={<SearchIcon />}
      width={width}
      height={height}
      onChange={handleInputChange}
      value={value}
      className={className}
    />
  );
};

export default SearchBar;
