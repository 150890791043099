import { useEffect, useState } from "react";
import { PlusIcon } from "assets/svg";
import styled from "styled-components";
import { device } from "constants";

export default function CreateNew() {
  const data = [
    "create purchase order",
    "create invoice",
    "create new project",
    "approve requests"
  ];
  const [, setAmount] = useState(null);
  const [, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setAmount(0);
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <div className="item-rows">
        {data.map((el, index) => (
          <div className="item-row" id={`row-${index}`}>
            <PlusIcon />
            <p className="capitalize">{el}</p>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 2px 4px 8px 1px #eeeeee;
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
  }

  .item-rows {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    flex-direction: column;

    @media ${device.isSmallDevice} {
      gap: 1rem;
    }

    .item-row {
      min-height: 3.1rem;
      border-radius: 0.8rem;
      width: 100%;
      padding: 0.8rem 1.6rem;
      display: flex;
      gap: 0.8rem;
      align-items: center;
      justify-content: flex-start;
      box-shadow: 2px 4px 8px 1px #eeeeee;

      @media ${device.isSmallDevice} {
        gap: 1rem;
        padding: 1.8rem 1.2rem;
        justify-content: center;
      }

      svg {
        width: 1.1rem;
        height: 1.1rem;

        path {
          fill: ${props => props.theme.primaryColor};
        }
      }

      p {
        color: ${props => props.theme.textBlack};
      }
    }
  }
`;
