import SelectElement, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import CircularProgress from 'components/CircularProgress';
import { SelectWrapper } from './Select.style';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { setBooleanProp } from 'utils';
import FormHelperText from '@mui/joy/FormHelperText';
import FormControl from '@mui/joy/FormControl';

const Select = props => {
  const indicator = props.loading ? (
    <CircularProgress size="sm" />
  ) : (
    <KeyboardArrowDown />
  );

  const handleSelectChange = (e, value) => {
    value && props.onChange({ target: { name: props.name, value } });
  };

  return (
    <SelectWrapper
      className={props.className}
      width={props.width}
      height={props.height}
      loading={setBooleanProp(props.loading)}
    >
      <FormControl error={props.error || !!props.errorMessage}>
        <SelectElement
          placeholder={props.placeholder}
          indicator={indicator}
          sx={{
            width: 240,
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
          onChange={handleSelectChange}
          value={props.value}
          name={props.name}
          error={props.error}
          disabled={props.disabled}
        >
          {props.options && props.options.length > 0 ? (
            props.options.map(option => (
              <Option key={option.id} value={option.value}>
                {option.template ? option.template : option.text}
              </Option>
            ))
          ) : (
            <Option disabled={true} value="">
              options unavailable
            </Option>
          )}
        </SelectElement>
        {/* Error Message  */}
        {props.errorMessage && (
          <FormHelperText>{props.errorMessage}</FormHelperText>
        )}
      </FormControl>
    </SelectWrapper>
  );
};

export default Select;
