import { Button, ButtonWrapper, Indicator } from './File.style';
import FormHelperText from '@mui/joy/FormHelperText';
import { DownloadIcon } from 'assets/svg';
import InputElement from '@mui/joy/Input';
import { useState } from 'react';
import FormControl from '@mui/joy/FormControl';
import CircularProgress from 'components/CircularProgress';

const File = props => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const placeholder = name ? name.slice(0, 15) : props.text;
  const indicator = loading ? <CircularProgress size="sm" /> : <DownloadIcon />;

  // in bytes
  const maxSize = props.maxSize ?? 1048576;

  function handleFileUpload(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (file.size <= maxSize) {
      reader.onloadstart = () => {
        setLoading(true);
      };
      reader.onloadend = () => {
        setName(file.name);
        setLoading(false);
        // set base64 result as input value
        // alternative: upload to 3rd party and set uri as value
        props.setFieldValue('image', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      props.setError('image', `Max file size is ${maxSize / 1024 / 1024}MB`);
    }
  }

  return (
    <ButtonWrapper>
      <FormControl error={props.error || props.errorMessage}>
        <Button
          width={props.width}
          height={props.height}
          variant="contained"
          component="label"
        >
          <span>{placeholder}</span>
          <InputElement
            hidden
            accept={props.accept}
            type="file"
            name={props.name}
            onChange={handleFileUpload}
            disabled={true}
          />
          <Indicator>{indicator}</Indicator>
        </Button>

        {/* Error message  */}
        {props.errorMessage && (
          <FormHelperText>{props.errorMessage}</FormHelperText>
        )}
      </FormControl>
    </ButtonWrapper>
  );
};

export default File;
