import { LuxuryHome } from '../../assets';
import { Layout, ImageBlock } from './LoggedOutLayout.style';

const LoggedOutLayout = ({ children }) => {
  return (
    <Layout>
      <div className="wrapper">
        <div className="outlet">{children}</div>
        <ImageBlock>
          <div className="img-frame">
            <img src={LuxuryHome} alt="luxury-home" />
          </div>
        </ImageBlock>
      </div>
    </Layout>
  );
};

export default LoggedOutLayout;
