import { api, addAuthorizationHeader } from './axios';
import { queryBuilder } from 'utils';

export const createPurchaseOrder = data => {
  return api.post('/create-purchase-order', data, addAuthorizationHeader());
};

export const getAllPurchaseOrders = ({ query }) => {
  return api.get(
    `/purchase-orders${queryBuilder(query, [
      'page',
      'search',
      'perPage',
      'supplier',
      'project',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getPurchaseOrder = id => {
  return api.get(`/purchase-order/${id}`, addAuthorizationHeader());
};

export const reviewPurchaseOrder = (id, data) => {
  return api.put(
    `/review-purchase-order/${id}`,
    data,
    addAuthorizationHeader(),
  );
};

export const getPurchaseOrderHistory = (id, requestId) => {
  return api.get(
    `/purchase-order/${requestId}/history/${id}`,
    addAuthorizationHeader(),
  );
};

export const updatePurchaseOrder = (id, data) => {
  return api.put(
    `/update-purchase-order/${id}`,
    data,
    addAuthorizationHeader(),
  );
};

export const getProjectPurchaseOrders = ({ projectId, query }) =>
  getAllPurchaseOrders({ query: { ...query, project: projectId } });
