import { MoneyIcon, StackIcon, UsersIcon } from 'assets/svg';
import { Wrapper } from './DashboardHeader.style';

export default function DashboardHeader() {
  return (
    <Wrapper>
      <div className="header-block">
        <div className="header-item">
          <MoneyIcon />
          <h4 className="b-700">&#8358; 0.00</h4>
          <p className="capitalize">total income</p>
        </div>
        <div className="header-item">
          <MoneyIcon />
          <h4 className="b-700">&#8358; 0.00</h4>
          <p className="capitalize">total expenses</p>
        </div>
        <div className="header-item">
          <StackIcon />
          <h4 className="b-700">0</h4>
          <p className="capitalize">projects</p>
        </div>
        <div className="header-item">
          <UsersIcon />
          <h4 className="b-700">0</h4>
          <p className="capitalize">customers</p>
        </div>
      </div>
    </Wrapper>
  );
}
