import { SideNavbar, TopNav } from 'components'
import MobileFooterNav from 'components/Updated/MobileFooterNav';
import UpdatedSidebar from 'components/Updated/Sidebar';
import { useMediaQuery } from 'react-responsive';

const UpdatedLayout = ({ children }) => {
    const isSmallDevice = useMediaQuery({ maxWidth: 768 });
    const isTabletDevice = useMediaQuery({ minWidth: 769, maxWidth: 1300 });

    return (
        <>
            {isSmallDevice || isTabletDevice ? (
                <div className="">
                    <TopNav />
                    <div className="p-3 min-h-screen">
                        {children}
                    </div>
                    <MobileFooterNav />
                </div>
            ) : (
                <div className='flex'>
                    <UpdatedSidebar />
                    <div className="w-full xl:w-[calc(100vw-280px)] xl:ml-[280px]">
                        <TopNav />
                        <div className="p-4">
                            {children}
                        </div>
                    </div>
                </div>
            )}

        </>

    )
}

export default UpdatedLayout