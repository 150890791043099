import moment from 'moment';
import { TableMenu } from 'components';
import { Badge } from 'components';
import { getTextColorByStatus } from 'utils/utils.text-color';

export const TableConfig = (rows = [], historyPageurl) => ({
  columns: [
    {
      title: 'Request by',
      template: rowData => {
        const { first_name, last_name } = rowData.written_by;
        return `${first_name} ${last_name}`;
      },
    },
    {
      title: 'Reviewed by',
      template: rowData => {
        if (!rowData.reviewed_by) return 'Yet to be reviewed';
        const { first_name, last_name } = rowData.reviewed_by;
        return `${first_name} ${last_name}`;
      },
    },
    {
      title: 'Time',
      template: rowData => moment(rowData.created_at).format('HH:mm'),
    },
    {
      title: 'Date',
      template: rowData => moment(rowData.created_at).format('DD MMM YYYY'),
    },
    {
      title: 'Status',
      template: rowData => (
        <Badge badgeColor={getTextColorByStatus(rowData.status)}>
          {rowData.status}
        </Badge>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '6rem',
      template: rowData => {
        return (
          <TableMenu
            width="13.8571rem"
            size="small"
            menuItems={[
              {
                text: 'View',
                url: historyPageurl(rowData.id),
              },
            ]}
          />
        );
      },
    },
  ],
  rows,
});
