import { CloseIcon } from 'assets/svg';
import { ModalContext } from 'context/ModalContext';
import { Modal } from './ModalGroup.style';

export default function ModalGroup() {
  return (
    <ModalContext.Consumer>
      {({
        isOpenModal,
        setIsOpenModal,
        modalType,
        modalTitle,
        content,
        onClose,
      }) => {
        const close = () => {
          if (onClose) {
            onClose();
          } else {
            setIsOpenModal(false);
          }
        };
        return (
          <Modal
            className="modal-wrapper"
            containerClassName="modal-container"
            closeOnOuterClick={true}
            show={isOpenModal}
            onClose={() => setIsOpenModal(false)}
          >
            <div className="header">
              <h6 className="title">{modalTitle}</h6>
              <CloseIcon onClick={close} />
            </div>
            <div className="content">{content}</div>
          </Modal>
        );
      }}
    </ModalContext.Consumer>
  );
}
