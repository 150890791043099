import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
};

const dimmerSlice = createSlice({
  name: 'dimmer',
  initialState,
  reducers: {
    showDimmer: (state, action) => {
      const { show } = action.payload;
      state.show = show;
    },
  },
});

export const { showDimmer } = dimmerSlice.actions;

export default dimmerSlice.reducer;
