import { lazy } from 'react';
import { urlBuilder } from 'utils';

const SuppliersDashboard = lazy(() => import('pages/Suppliers/Dashboard'));
const SupplierDetails = lazy(() => import('pages/Suppliers/SupplierDetails'));

export const SUPPLIERS_ROUTES_PATHS = {
  DASHBOARD_PAGE: () => '/suppliers',
  DETAILS_PAGE: ({ id } = {}) =>
    urlBuilder(['suppliers', id ? id : ':id', 'details']),
};

const SuppliersRoutes = () => [
  {
    path: SUPPLIERS_ROUTES_PATHS.DASHBOARD_PAGE(),
    element: <SuppliersDashboard />,
    protected: true,
  },
  {
    path: SUPPLIERS_ROUTES_PATHS.DETAILS_PAGE(),
    element: <SupplierDetails />,
    protected: true,
  },
];

export default SuppliersRoutes;
