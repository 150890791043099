import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleNetworkRequest } from 'utils/networkRequest';
import { generateNewFilter } from './reducer';

const getTableAndPaginationData = createAsyncThunk(
  'tableAndPagination/getTableAndPaginationData',
  async (data, { getState, dispatch }) => {
    const { api, tableId, apiParams } = data;

    const {
      tableAndPagination: { tableDataFilters },
    } = getState();

    const query = tableDataFilters[tableId] || generateNewFilter();

    return handleNetworkRequest(dispatch, () => api({ ...apiParams, query }), {
      showSuccessMessage: false,
    });
  },
);

export { getTableAndPaginationData };
