import { createSlice } from '@reduxjs/toolkit';
import {
  getStaffEmploymentTypes,
  getStaffPositions,
  getAllStaff,
  updateStaff,
  getStaffById,
} from './actions';

const initialState = {
  activeStaff: null,
  staff: [],
  staff_employment_types: [],
  staff_positions: [],
  // Loading States
  loading: false,
  staff_employment_types_loading: false,
  staff_positions_loading: false,
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //getStaffEmploymentTypes
    builder.addCase(getStaffEmploymentTypes.pending, state => {
      state.staff_employment_types_loading = true;
    });
    builder.addCase(getStaffEmploymentTypes.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.staff_employment_types = data.categories;
      state.staff_employment_types_loading = false;
    });
    builder.addCase(getStaffEmploymentTypes.rejected, (state, action) => {
      state.staff_employment_types_loading = false;
    });

    //getStaffPositions
    builder.addCase(getStaffPositions.pending, state => {
      state.staff_positions_loading = true;
    });
    builder.addCase(getStaffPositions.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.staff_positions = data.categories;
      state.staff_positions_loading = false;
    });
    builder.addCase(getStaffPositions.rejected, (state, action) => {
      state.staff_positions_loading = false;
    });

    //getAllStaff
    builder.addCase(getAllStaff.pending, state => {
      state.loading = true;
    });
    builder.addCase(getAllStaff.fulfilled, (state, action) => {
      const { data } = action.payload.data;
      state.staff = data;
      state.loading = false;
    });
    builder.addCase(getAllStaff.rejected, state => {
      state.loading = false;
    });

    //getStaffById
    builder.addCase(getStaffById.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeStaff = data;
    });

    //updateStaff
    builder.addCase(updateStaff.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateStaff.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateStaff.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {} = staffSlice.actions;

export default staffSlice.reducer;
