import { createAsyncThunk } from '@reduxjs/toolkit';
import * as SupplierApi from 'api/suppliers';
import { handleNetworkRequest } from 'utils/networkRequest';

const createSupplier = createAsyncThunk(
  'suppliers/createSupplier',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () => SupplierApi.createSupplier(data)),
);

const updateSupplier = createAsyncThunk(
  'suppliers/updateSupplier',
  async ({ id, data }, { dispatch }) =>
    handleNetworkRequest(dispatch, () => SupplierApi.updateSupplier(id, data)),
);

const getSupplierById = createAsyncThunk(
  'suppliers/getSupplierById',
  async ({ id }, { dispatch }) =>
    handleNetworkRequest(dispatch, () => SupplierApi.getSupplierById(id), {
      showSuccessMessage: false,
    }),
);

const getSupplierNames = createAsyncThunk(
  'suppliers/getSupplierNames',
  async (_args, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () =>
        SupplierApi.getAllSuppliers({
          query: {
            perPage: 100000,
          },
        }),
      { showSuccessMessage: false, showPageLoader: false },
    ),
);

export { getSupplierById, createSupplier, updateSupplier, getSupplierNames };
