import { lazy } from 'react';
import DashboardRoutes from './DashboardRoutes';
import WarehouseRoutes from './WarehouseRoutes';
import ProjectRoutes from './ProjectRoutes';
import RequestsRoutes from './RequestsRoutes';
import StaffRoutes from './StaffRoutes';
import SuppliersRoutes from './SuppliersRoutes';

const Finances = lazy(() => import('pages/Finances/Finances'));
const Customers = lazy(() => import('pages/Customers/Customers'));

const Leases = lazy(() => import('pages/Leases/Leases'));
const Customer = lazy(() => import('pages/Customers/Customer'));
const Finance = lazy(() => import('pages/Finances/Finance'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
// const EmailReset = lazy(() => import('pages/ForgotPassword/EmailReset'))
const ForgotPassword = lazy(() =>
  import('pages/ForgotPassword/ForgotPassword'),
);
const EmailPrompt = lazy(() => import('pages/ForgotPassword/index'));
const TestModals = lazy(() => import('pages/TestModals'));
const NoMatch = lazy(() => import('pages/NoMatch/'));

const PageRoutes = () => [
  ...DashboardRoutes(),
  ...WarehouseRoutes(),
  ...ProjectRoutes(),
  ...RequestsRoutes(),
  ...StaffRoutes(),
  ...SuppliersRoutes(),
  { path: '/finances', element: <Finances />, protected: true },
  { path: '/customers', element: <Customers />, protected: true },
  { path: '/leases', element: <Leases />, protected: true },
  { path: '/customer/:id', element: <Customer />, protected: true },
  { path: '/finance/:id', element: <Finance />, protected: true },
  { path: '/change-password', element: <ChangePassword /> },
  // { path: '/emailReset', element: <EmailReset />, protected: true },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/email-prompt', element: <EmailPrompt /> },

  { path: '/testmodals', element: <TestModals />, protected: true },

  { path: '*', element: <NoMatch />, protected: true },
  {
    path: '/reports',
    element: <h4>In progress.....coming soon.</h4>,
    protected: true,
  },
  {
    path: '/settings',
    element: <h4>In progress.....coming soon.</h4>,
    protected: true,
  },
  { path: '*', element: <h4>Error 404...not found.</h4>, protected: true },
];

export default PageRoutes;
