import { createSlice } from '@reduxjs/toolkit';
import {
  getWarehouseItemCategories,
  getWarehouseItemsName,
  getMasterListitems,
} from './action';

const initialState = {
  mainWarehouseItems: [],
  warehouseItemCategories: [],
  warehouseItemsName: [],
  masterListItems: [],
  // Loading statuses
  warehouseItemCategoriesLoading: false,
  warehouseItemsNameLoading: false,
};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getMasterListitems
    builder.addCase(getMasterListitems.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.masterListItems = data.items;
    });

    //getWarehouseItemCategories
    builder.addCase(getWarehouseItemCategories.pending, state => {
      state.warehouseItemCategoriesLoading = true;
    });
    builder.addCase(getWarehouseItemCategories.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.warehouseItemCategories = data.categories;
      state.warehouseItemCategoriesLoading = false;
    });
    builder.addCase(getWarehouseItemCategories.rejected, state => {
      state.warehouseItemCategoriesLoading = false;
    });

    // getWarehouseItemsName
    builder.addCase(getWarehouseItemsName.pending, state => {
      state.warehouseItemsNameLoading = true;
    });
    builder.addCase(getWarehouseItemsName.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.warehouseItemsName = data.items;
      state.warehouseItemsNameLoading = false;
    });
    builder.addCase(getWarehouseItemsName.rejected, state => {
      state.warehouseItemsNameLoading = false;
    });
  },
});

export const {} = warehouseSlice.actions;

export default warehouseSlice.reducer;
