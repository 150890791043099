import styled from "styled-components";
import { device } from "constants";

export const Wrapper = styled.div`
	padding-inline: 1.85rem;
	margin-top: 6.2rem;
	overflow-x: hidden;

	margin-left: ${(props) => {
		if (props.page === "login" || props.isSmallDevice) {
			return 0;
		} else {
			return props.theme.sideBarWidth;
		}
	}};

	@media ${device.laptop} {
		margin-left: ${(props) => {
			if (props.page === "login" || props.isSmallDevice) {
				return 0;
			} else {
				return "11.8rem";
			}
		}};
	}
 
	@media ${device.isSmallDevice} {
		padding-inline: 0.65rem;
	}

	@media ${device.tablet} {
		min-height: calc(100vh - 1rem);
		padding-bottom: 1rem;
	}
`;