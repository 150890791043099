import { createSlice, current } from '@reduxjs/toolkit';
import { getTableAndPaginationData } from './actions';

export const generateNewFilter = () => ({
  perPage: 10,
  page: 1,
  search: '',
});

/**
 * structure of data in state
 *
 *  const initialState = {
 *       tableData: {
 *          [tableId]: main data,
 *      },
 *      tableDataPagination: {
 *          [tableId]: main data,
 *       },
 *      tableDataFilters: {
 *          [tableId]: main data,
 *      },
 *  }
 *
 *   This is so that if more than one table exists in a page, the tableId will differentiate data for each table
 */

const initialState = {
  tableData: {},
  tableDataPagination: {},
  tableDataFilters: {},
};

const tableAndPaginationSlice = createSlice({
  name: 'tableAndPagination',
  initialState,
  reducers: {
    clearTableData: (state, action) => {
      const { tableId } = action.payload;

      if (state.tableData[tableId]) delete state.tableData[tableId];

      if (state.tableDataPagination[tableId])
        delete state.tableDataPagination[tableId];

      if (state.tableDataFilters[tableId])
        delete state.tableDataFilters[tableId];
    },
    updateTableDataFilterStruture: (state, action) => {
      const { tableId, structure } = action.payload;
      const prevStructure = state.tableDataFilters[tableId];

      const filters = {
        ...state.tableDataFilters,
      };

      if (prevStructure) {
        filters[tableId] = { ...prevStructure, ...structure };
      } else {
        filters[tableId] = { ...generateNewFilter(), ...structure };
      }
      state.tableDataFilters = filters;
    },
    updateTableDataFilters: (state, action) => {
      const { key, value, tableId, isMultiple, values } = action.payload;
      const filter = { ...state.tableDataFilters[tableId] };

      let filters;

      if (isMultiple) {
        filters = {
          ...state.tableDataFilters,
          [tableId]: { ...filter, ...values },
        };
      } else {
        filter[key] = value;
        filters = { ...state.tableDataFilters, [tableId]: filter };
      }

      state.tableDataFilters = filters;
    },
  },
  extraReducers: builder => {
    // getTableAndPaginationData
    builder.addCase(getTableAndPaginationData.fulfilled, (state, action) => {
      const { tableId } = action.meta.arg;
      const { data, pagination } = action.payload.data;
      const newTableData = { ...state.tableData, [tableId]: data };
      const newTableDataPagination = {
        ...state.tableDataPagination,
        [tableId]: pagination,
      };
      state.tableData = newTableData;
      state.tableDataPagination = newTableDataPagination;

      if (!state.tableDataFilters[tableId]) {
        const filters = {
          ...state.tableDataFilters,
          [tableId]: generateNewFilter(),
        };
        state.tableDataFilters = filters;
      }
    });
  },
});

export const {
  clearTableData,
  updateTableDataFilters,
  updateTableDataFilterStruture,
} = tableAndPaginationSlice.actions;

export default tableAndPaginationSlice.reducer;
