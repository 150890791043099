import Textarea from '@mui/joy/Textarea';
import { TextAreaWrapper } from './TextArea.style';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';

const TextArea = props => {
  const hasError = props.errorMessage ? true : false;

  return (
    <TextAreaWrapper
      width={props.width}
      height={props.height}
      className={props.className}
      loading={props.loading}
    >
      <FormControl error={hasError}>
        {/* Label  */}
        {props.label && <FormLabel>{props.label}</FormLabel>}

        {/* Input  */}
        <Textarea
          variant="outlined"
          minRows={3}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          error={hasError}
          placeholder={props.placeholder}
        />

        {/* Error Message  */}
        {hasError && <FormHelperText>{props.errorMessage}</FormHelperText>}
      </FormControl>
    </TextAreaWrapper>
  );
};

export default TextArea;
