import { createSlice } from '@reduxjs/toolkit';
import {
  createExpenseRequest,
  getExpenseRequestCategories,
  getExpenseRequest,
  reviewExpenseRequest,
  updateExpenseRequest,
  getExpenseRequestHistory,
} from './actions';

const initialState = {
  expenseRequestCategories: [],
  activeExpenseRequest: null,
  activeExpenseRequestHistory: null,
  // pagination: null,
  expenseRequestFilterParams: {
    perPage: 10,
    page: 1,
    search: '',
    category: '',
  },
  // Loading statuses
  expenseRequestCategoriesLoading: false,
};

const expenseRequestSlice = createSlice({
  name: 'expenseRequest',
  initialState,
  reducers: {
    updateExpenseRequertFilterParams: (state, action) => {
      const { key, value } = action.payload;
      const temp = { ...state.expenseRequestFilterParams };
      temp[key] = value;
      state.expenseRequestFilterParams = temp;
    },
    updateWarehouseItemsFilterParams: (state, action) => {
      const { key, value } = action.payload;
      const temp = { ...state.warehouseItemsFilterParams };
      temp[key] = value;
      state.warehouseItemsFilterParams = temp;
    },
  },
  extraReducers: builder => {
    // getExpenseRequestCategories
    builder.addCase(getExpenseRequestCategories.pending, state => {
      state.expenseRequestCategoriesLoading = true;
    });
    builder.addCase(getExpenseRequestCategories.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.expenseRequestCategories = data.categories;
      state.expenseRequestCategoriesLoading = false;
    });
    builder.addCase(getExpenseRequestCategories.rejected, (state, action) => {
      state.expenseRequestCategoriesLoading = false;
    });

    // createExpenseRequest
    builder.addCase(createExpenseRequest.fulfilled, (state, action) => {
      // const data = action.payload.data;
    });

    // getExpenseRequest
    builder.addCase(getExpenseRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeExpenseRequest = data;
    });

    // reviewExpenseRequest
    builder.addCase(reviewExpenseRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeExpenseRequest = data;
    });

    // updateExpenseRequest
    builder.addCase(updateExpenseRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeExpenseRequest = data;
    });

    // getExpenseRequestHistory
    builder.addCase(getExpenseRequestHistory.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeExpenseRequestHistory = data;
    });
  },
});

export const {
  updateWarehouseItemsFilterParams,
  updateExpenseRequertFilterParams,
} = expenseRequestSlice.actions;

export default expenseRequestSlice.reducer;
