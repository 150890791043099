import ButtonElement from '@mui/joy/Button';
import styled, { css } from 'styled-components';
import { getConditionalClass } from 'utils';

export const ButtonWrapper = styled.div.attrs(props => ({
  className: `input-wrapper ${getConditionalClass(props.className)}`,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const Button = styled(ButtonElement)`
  &.MuiButton-root {
    min-width: ${props => props.width || '2.5rem'};
    min-height: ${props => props.height || '2.8571rem'};
    border-radius: 0.2142rem;
    border: 0.07142rem solid #5a5a5a80;
    --Input-focusedHighlight: ${props => props.theme.primaryColor} !important;
    background: ${props => props.theme.milkyWhite};
    display: flex;
    justify-content: flex-start;
    color: #5a5a5a80;

    &.Mui-focused::before {
      box-shadow: 0 0 0 1px ${props => props.theme.primaryColor};
      border: 0.07142rem solid ${props => props.theme.primaryColor};
    }
  }

  span:first-child {
    width: 73%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Indicator = styled.span`
  position: absolute;
  right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 50%;

  svg.not(.MuiCircularProgress-svg) {
    width: 1.4rem;
    height: 1.4rem;
  }

  .MuiCircularProgress-root {
    circle:first-of-type {
      stroke: ${props => props.theme.veryLightOrange};
    }
    circle:last-of-type {
      stroke: ${props => props.theme.primaryColor};
    }
  }
`;
