import styled from 'styled-components';
const menuWrapperPadding = '0.7142rem';

export const Wrapper = styled.aside.attrs(() => ({
  className: 'side-nav',
}))`
  background-color: ${props => props.theme.navyblue};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'logo'
    'menu'
    'logout';

  // &.close {
  //   display: none;
  // }

  .logo {
    grid-area: logo;

    display: flex;
    margin: 1.3571rem auto 3rem auto;
  }

  .menu {
    grid-area: menu;
    overflow-y: auto;
  }

  .logout {
    grid-area: logout;
  }

  li,
  .link-dropdown,
  .logout {
    padding: 0.8571rem 1.0714rem;
    font-size: 1.1428rem;
    font-weight: 400;
    color: ${props => props.theme.white};
    border-radius: 0.5714rem;
    cursor: pointer;

    path {
      fill: ${props => props.theme.white};
    }

    svg {
      margin-right: 1.7142rem;
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      color: ${props => props.theme.white};
      background: ${props => props.theme.primaryColor};

      path {
        fill: ${props => props.theme.white};
      }
    }

    &.active {
      color: ${props => props.theme.primaryColor};
      background: ${props => props.theme.white};
      font-weight: 600;

      path {
        fill: ${props => props.theme.primaryColor};
      }
    }
  }
`;

export const NavItems = styled.ul.attrs(() => ({
  className: 'menu',
}))`
  padding: ${menuWrapperPadding};
  overflow-y: auto;

  a {
    text-decoration: none;
    color: inherit;

    &:not(:last-of-type) {
      li {
        margin-bottom: 0.5714rem;
      }
    }
  }

  .link-dropdown {
    display: flex;
    align-items: center;

    &.sub-menu-show {
      .arrow-down-icon {
        transform: rotate(180deg);
      }
    }

    .arrow-down-icon {
      margin-left: auto;
      transition:
        transform 0.1s ease,
        opacity 0.1s ease;

      svg {
        margin: 0;
      }
    }
  }
`;

export const Logout = styled.div.attrs(() => ({
  className: 'logout',
}))`
  margin: ${menuWrapperPadding};
  margin-bottom: 4rem;
`;
