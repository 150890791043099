import { lazy } from 'react';

const Login = lazy(() => import('pages/Login'));
const ResetPassword = lazy(() => import('pages/ForgotPassword/index'));
const EmailReset = lazy(() => import('pages/ForgotPassword/EmailReset'))
// const ChangePassword = lazy(() => import('pages/ChangeEmail'));

const AuthRoutes = () => [
  { path: '/login', element: <Login />, index: true },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '/emailReset', element: <EmailReset />, protected: true },
  // { path: '/change-password', element: <ChangePassword /> },
  { path: '*', element: <></>, protected: true },
];

export default AuthRoutes;
