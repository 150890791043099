export const getTextColorByStatus = status => {
  switch (status) {
    case 'pending':
      return 'orange';
    case 'approved':
      return 'green';
    case 'declined':
      return 'red';
    default:
      return '';
  }
};
