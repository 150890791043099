import { showSnackBar } from 'store/snackbar/reducer';
import { showDimmer } from 'store/dimmer/reducer';

export const handleNetworkRequest = async (dispatch, apiCall, options) => {
  const {
    showPageLoader = true,
    showSuccessMessage = true,
    customSuccessMessage,
    showErrorMessage = true,
    customErrorMessage,
  } = options || {};

  let response;

  try {
    // if (!navigator.onLine) {
    //   dispatch(
    //     showSnackBar({
    //       show: true,
    //       isError: true,
    //       message: 'No internet connection',
    //     }),
    //   );
    //   return;
    // }

    if (showPageLoader) dispatch(showDimmer({ show: true }));

    response = await apiCall();

    if (showPageLoader) dispatch(showDimmer({ show: false }));

    if (showSuccessMessage) {
      dispatch(
        showSnackBar({
          show: true,
          message: customSuccessMessage || response.data.message,
        }),
      );
    }
  } catch (err) {
    if (showPageLoader) dispatch(showDimmer({ show: false }));

    if (showErrorMessage) {
      dispatch(
        showSnackBar({
          show: true,
          message: customErrorMessage || err?.response?.data?.message,
          isError: true,
        }),
      );
    }
  }

  return response.data;
};
