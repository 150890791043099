import { createSlice } from '@reduxjs/toolkit';

/**
 * structure of data in state
 *
 *  const initialState = {
 *       activeTabIndex: {
 *          [tabId]: index value,
 *      },
 *
 *   This is so that if there more than one tab exists in a page, the tabId will differentiate data for each tab
 */

const initialState = {
  activeTabIndex: {},
  defaultIndex: 0,
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    resetTableData: (state, action) => {
      const { tabId } = action.payload;

      if (state.tableData[tabId]) delete state.tableData[tabId];

      if (state.tableDataPagination[tabId])
        delete state.tableDataPagination[tabId];

      if (state.tableDataFilters[tabId]) delete state.tableDataFilters[tabId];
    },
    updateActiveTabIndex: (state, action) => {
      const { tabIndex, tabId } = action.payload;
      const indexes = { ...state.activeTabIndex, [tabId]: tabIndex };
      state.activeTabIndex = indexes;
    },
  },
});

export const { resetTableData, updateActiveTabIndex } = tabSlice.actions;

export default tabSlice.reducer;
