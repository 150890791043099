import styled from 'styled-components';
import { device } from 'constants';

export const Wrapper = styled.div`
  margin-block: 1rem;
  padding: 0;
  border-radius: 0;
  border: 1px solid ${props => props.theme.grey100};
  // box-shadow: 2px 4px 8px 1px #eeeeee;

  @media ${device.isSmallDevice} {
    margin-top: 1.5rem;
  }

  .empty {
    color: ${props => props.theme.greyText};
    text-align: center;
    margin-block: 1rem;
  }
`;

export const FieldHeader = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  cursor: pointer;

  .field-titles {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    justify-content: space-between;
    padding: 0.8rem;
    background: rgba(255, 105, 0, 0.1);

    @media ${device.isSmallDevice} {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 991px) {
      padding: 1rem;
    }

    p {
      font-weight: 600;
      width: 6.3rem;
      // min-width: 6.3rem;
      // max-width: 6.3rem;
      margin-right: 1em;

      @media ${device.isSmallDevice} {
        max-width: none;
      }

      @media (max-width: 520px) {
        font-size: 0.8rem;
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  &.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.nopagination {
    margin-bottom: 3rem;

    p, ul {
      display: none;
    }
  }

  .pagination-row {
    list-style: none;
    display: flex;
    gap: 0.8rem;
    align-self: flex-end;
    margin: 0;
    flex-wrap: wrap;
    width: 40vw;
    justify-content: flex-end;
    margin-bottom: 1.5rem;

    li {
      padding: 0.2rem 0.5rem;
      border: 1px solid ${props => props.theme.grey100};

      @media ${device.isSmallDevice} {
        padding: 3px 9px;
      }

      a {
        color: ${props => props.theme.primaryColor} !important;
      }

      &.selected {
        background-color: ${props => props.theme.primaryColor};
        border: none;

        a {
          color: white !important;
        }
      }
    }

    .previous.disabled,
    .next.disabled {
      pointer-events: none;
      a {
        color: ${props => props.theme.grey100} !important;
        pointer-events: none;
      }
    }
  }
`;

export const DataRow = styled.li`
  display: flex;
  padding: 0.8rem;
  border-radius: 0.4rem;
  align-items: center;
  width: fit-content;
  min-width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

  @media (max-width: 991px) {
    padding: 0.75rem;
  }

  @media ${device.isSmallDevice} {
    // width: 100%;
    display: flex;
    justify-content: space-between;
  }

  p {
    text-transform: capitalize;
    min-width: 6.3rem;
    max-width: 6.3rem;
    margin-right: 1em;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${device.isSmallDevice} {
      max-width: none;
    }

    @media (max-width: 520px) {
      font-size: 0.7rem;
    }
  }

  svg {
    &.row-icon {
      width: 1rem;
      height: 1rem;
      path {
        fill: ${props => props.theme.greyText};
      }
    }
    &.dot-icon {
      width: 0.4rem;
      height: 0.4rem;
      path {
        fill: ${props => props.theme.greyText};
      }
    }
  }

  .pending,
  .ongoing,
  .transit {
    color: #ffa500 !important;
    background-color: rgba(255, 165, 0, 0.1);

    .dot {
      background-color: #ffa500;
    }

    svg {
      circle {
        fill: #ffa500;
      }
    }
  }

  .in.progress {
    color: ${props => props.theme.primaryColor};
    background-color: rgba(255, 165, 0, 0.1);


    .dot {
      background-color: ${props => props.theme.primaryColor};
    }

    svg {
      circle {
        fill: ${props => props.theme.primaryColor};
      }
    }
  }

  .approved,
  .completed,
  .delivered {
    color: #008000 !important;
    background-color: rgba(0, 128, 0, 0.1);

    .dot {
      background-color: #008000;
    }

    svg {
      circle {
        fill: #008000;
      }
    }
  }

  .declined {
    color: #ff0000 !important;
    background-color: rgba(255, 0, 0, 0.1);

    .dot {
      background-color: #ff0000;
    }

    svg {
      circle {
        fill: #ff0000;
      }
    }
  }

  .picked.up {
    color: #2196f3 !important;
    background-color: rgba(33, 150, 243, 0.1);

    .dot {
      background-color: #2196f3;
    }

    svg {
      circle {
        fill: #2196f3;
      }
    }
  }

  .status-badge,
  .confirm-badge {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0.7rem;
    gap: 0.5rem;
    height: auto;
  }

  .confirm-badge {
    border-radius: 0.3rem;
    color: ${props => props.theme.primaryColor} !important;
    background-color: rgba(255, 105, 0, 0.1);

    &.true {
      color: #fff !important;
      background-color: #008000;
    }
  }
`;