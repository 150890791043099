import { api, addAuthorizationHeader } from './axios';
import { CATEGORY_TYPES, createCategory, getCategoryByType } from './category';
import { queryBuilder } from 'utils';

export const createStaffEmploymentType = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.STAFF_EMPLOYMENT_TYPE };
  return createCategory(updatedData);
};

export const createStaffPosition = data => {
  const updatedData = { ...data, type: CATEGORY_TYPES.STAFF_POSITION };
  return createCategory(updatedData);
};

export const getStaffPositions = () => {
  return getCategoryByType([CATEGORY_TYPES.STAFF_POSITION]);
};

export const getStaffEmploymentTypes = () => {
  return getCategoryByType([CATEGORY_TYPES.STAFF_EMPLOYMENT_TYPE]);
};

export const getStaffRoles = () => {
  return getCategoryByType(CATEGORY_TYPES.rol);
};

export const createStaff = data => {
  return api.post('/create-staff', data, addAuthorizationHeader());
};

export const getAllStaff = ({ query }) => {
  return api.get(
    `/staff${queryBuilder(query, [
      'page',
      'search',
      'role',
      'position',
      'employment_type',
      'perPage',
    ])}`,
    addAuthorizationHeader(),
  );
};

export const getStaffById = id => {
  return api.get(`/staff-by-id/${id}`, addAuthorizationHeader());
};

export const updateStaff = (id, data) => {
  return api.put(`/update-staff/${id}`, data, addAuthorizationHeader());
};
