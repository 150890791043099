import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { checkActiveLink, cn } from 'utils';
import { FaChevronDown } from 'react-icons/fa';

const SubMenu = props => {
  const location = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);

  useEffect(() => {
    const submenu = props.submenu;
    let active = false;

    for (let i = 0; i < submenu.length; i++) {
      const url = submenu[i].url;

      if (checkActiveLink(location, url, false)) {
        active = true;
        break;
      }
    }

    setSubmenuActive(active);
    setShowSubMenu(active);
  }, [location, props.submenu]);

  return (
    <div className="overflow-y-auto">
      <div
        className={cn(
          'text-base px-6 space-x-3 py-2 rounded-lg text-white hover:bg-white hover:!text-orange flex items-center justify-between w-full',
          {
            'text-orange/80 bg-white hover:!text-orange hover:font-semibold font-semibold hover:bg-white':
              submenuActive,
          },
        )}
        onClick={() => setShowSubMenu(!showSubMenu)}
      >
        <div className="flex items-center justify-between w-full hover:font-semibold">
          <div className="flex items-center space-x-5">
            <div>{props.icon}</div>
            <p>{props.title}</p>
          </div>
          <FaChevronDown
            className={`ml-10 transition-transform transform ${
              showSubMenu ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </div>
      </div>

      {showSubMenu && (
        <div className="bg-white rounded-lg px-3 py-2 max-h-56 overflow-y-auto space-y-3 my-2 transition-all duration-300">
          {props.submenu.map((item, index) => (
            <NavLink
              to={item.url}
              key={`sub-nav-item-${index}`}
              className={cn(
                'flex items-center text-base py-2 px-3 text-navy rounded-lg transition duration-300 ease-in-out',
                {
                  'hover:bg-navy hover:text-white': !checkActiveLink(
                    location,
                    item.url,
                    item.exact,
                  ),
                  'bg-navy text-white': checkActiveLink(
                    location,
                    item.url,
                    item.exact,
                  ),
                },
              )}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubMenu;
