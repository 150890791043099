import { createAsyncThunk } from '@reduxjs/toolkit';
import * as WarehouseApi from 'api/warehouse';
import { handleNetworkRequest } from 'utils/networkRequest';

const createWarehouseItemCategory = createAsyncThunk(
  'warehouse/createWarehouseItemCategory',
  async ({ name, description }, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      WarehouseApi.createWarehouseItemCategory({ name, description }),
    ),
);

const getWarehouseItemCategories = createAsyncThunk(
  'warehouse/getWarehouseItemCategories',
  async (_arg, { dispatch }) =>
    handleNetworkRequest(
      dispatch,
      () => WarehouseApi.getWarehouseItemCategories(),
      {
        showPageLoader: false,
        showSuccessMessage: false,
      },
    ),
);

const createWarehouseItem = createAsyncThunk(
  'warehouse/createWarehouseItem',
  async (data, { dispatch }) =>
    handleNetworkRequest(dispatch, () =>
      WarehouseApi.createWarehouseItem(data),
    ),
);

const getWarehouseItemsName = createAsyncThunk(
  'warehouse/getWarehouseItemsName',
  async (_args, { dispatch }) =>
    handleNetworkRequest(dispatch, () => WarehouseApi.getWarehouseItemsName(), {
      showSuccessMessage: false,
      showPageLoader: false,
    }),
);

const getMasterListitems = createAsyncThunk(
  'warehouse/getMasterListitems',
  async (_args, { dispatch }) =>
    handleNetworkRequest(dispatch, () => WarehouseApi.getMasterListitems(), {
      showSuccessMessage: false,
      showPageLoader: false,
    }),
);

export {
  createWarehouseItemCategory,
  getWarehouseItemCategories,
  createWarehouseItem,
  getWarehouseItemsName,
  getMasterListitems,
};
