import { createSlice } from '@reduxjs/toolkit';
import { getProfile, changePassword, emailResetCode, resetPassword } from './actions';

const initialState = {
  profile: null,
  email: "",

  // Loading statuses
  profileLoading: false,
  passwordLoading: false,
  resetCodeLoading: false,
  passwordResetLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //getProfile
    builder.addCase(getProfile.pending, state => {
      state.profileLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      const { profile } = action.payload.data;

      state.profile = profile;
      state.profileLoading = false;
    });

    builder.addCase(getProfile.rejected, (state, action) => {
      state.profileLoading = false;
    });

     //changePassword
    builder.addCase(changePassword.pending, state => {
      state.passwordLoading = true;
    });

    builder.addCase(changePassword.fulfilled, (state, action) => {

      state.passwordLoading = false;
    });

    builder.addCase(changePassword.rejected, state => {
      state.passwordLoading = false;
    });

    //resetCode
    builder.addCase(emailResetCode.pending, state => {
      state.resetCodeLoading = true;
    });

    builder.addCase(emailResetCode.fulfilled, (state, action) => {
      const { email } = action.payload.data;

      state.email = email;
      state.resetCodeLoading = false;
    });

    builder.addCase(emailResetCode.rejected, state => {
      state.resetCodeLoading = false;
    });

    //reset password
    builder.addCase(resetPassword.pending, state => {
      state.passwordResetLoading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {

      state.passwordResetLoading = false;
    });

    builder.addCase(resetPassword.rejected, state => {
      state.passwordResetLoading = false;
    });
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
