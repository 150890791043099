import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { useModalContext } from 'context/ModalContext';
import { Layout } from './LoggedInLayout.style';
import TopNav from './TopNav';
import MainPage from './MainPage';
import SideNav from './SideNav';
import UpdatedSidebar from 'components/Updated/Sidebar';

const LoggedInLayout = ({ children }) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 768 });

  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  const { setModalType, setIsOpenModal } = useModalContext();

  return (
    <Layout isSmallDevice={isSmallDevice}>
      {/* className="top-nav" */}
      <TopNav
        isSmallDevice={isSmallDevice}
        setIsSideNavOpen={setIsSideNavOpen}
        setModalType={setModalType}
        setIsOpenModal={setIsOpenModal}
      />

      {/* className="side-nav" */}

      {/* <SideNav
        isSideNavOpen={isSideNavOpen}
        setIsSideNavOpen={setIsSideNavOpen}
      /> */}
      <UpdatedSidebar />

      {/* className="main-page" */}
      <MainPage>{children}</MainPage>
    </Layout>
  );
};

export default LoggedInLayout;
