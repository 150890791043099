import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { MenuHorizontal } from 'assets/svg';
import { TableMenuWrapper } from './TableMenu.style';
import { NavLink } from 'react-router-dom';

export default function TableMenu(props) {
  return (
    <TableMenuWrapper>
      <Dropdown>
        <MenuButton disabled={props.disabled} variant="plain" type={props.type}>
          <MenuHorizontal className="dot-icon" />
        </MenuButton>
        {props.menuItems && (
          <Menu size="md" placement="bottom-end">
            {props.menuItems.map((item, index) =>
              item.url ? (
                <NavLink to={item.url} key={index}>
                  <MenuItem onClick={item.onClick}>{item.text}</MenuItem>
                </NavLink>
              ) : (
                <MenuItem key={index} onClick={item.onClick}>
                  {item.text}
                </MenuItem>
              ),
            )}
          </Menu>
        )}
      </Dropdown>
    </TableMenuWrapper>
  );
}
