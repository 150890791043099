import { device } from 'constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
  }

  img {
    width: 50%;

    @media (max-width: 650px) {
        width: 100%;
    }
  }

  & > .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8rem;

    @media (max-width: 650px) {
        flex-direction: column;
    }
  }
  
`;
