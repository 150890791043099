import { createSlice } from '@reduxjs/toolkit';
import {
  createProject,
  getAllProjects,
  getProjectById,
  updateProjectStatus,
  updateProject,
  getProjectsCodes,
  getProjectStatuses,
  getBuildingPurposes,
  getConcreteTypes,
  getFurnitureTypes,
  getBuildingUnitTypes,
  getOtherRooms,
} from './actions';

const initialState = {
  loading: false,
  projectsCodesLoading: false,
  projectStatusesLoading: false,
  project: null,
  building: null,
  furniture: null,
  concrete: null,
  units: [],
  projectsCodes: [],
  projects: [],
  activeProject: null,
  projectStatuses: [],
  buildingPurposesLoading: false,
  buildingPurposes: [],
  concreteTypesLoading: false,
  concreteTypes: [],
  furnitureTypesLoading: false,
  furnitureTypes: [],
  buildingUnitTypesLoading: false,
  buildingUnitTypes: [],
  otherRoomsLoading: false,
  otherRooms: [],
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProject: (state, action) => {
      const { type, values } = action.payload;
      state[type] = values;
    },
    clearCache: state => {
      state.project = null;
      state.building = null;
      state.furniture = null;
      state.concrete = null;
      state.units = [];
    },
    setUnits: (state, action) => {
      state.units.push(action.payload);
    },
  },
  extraReducers: builder => {
    //getProjectStatuses
    builder.addCase(getProjectStatuses.pending, state => {
      state.projectStatusesLoading = true;
    });
    builder.addCase(getProjectStatuses.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.projectStatuses = data.categories;
      state.projectStatusesLoading = false;
    });
    builder.addCase(getProjectStatuses.rejected, (state, action) => {
      state.projectStatusesLoading = false;
    });

    // getAllProjects
    builder.addCase(getAllProjects.pending, state => {
      state.loading = true;
    });
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.projects = data.projects;
      state.pagination = data.pagination;
      state.loading = false;
    });
    builder.addCase(getAllProjects.rejected, state => {
      state.loading = false;
    });

    // createProject
    builder.addCase(createProject.pending, state => {
      state.loading = true;
    });
    builder.addCase(createProject.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(createProject.rejected, state => {
      state.loading = false;
    });
    // getProjectsCodes
    builder.addCase(getProjectsCodes.pending, state => {
      state.projectsCodesLoading = true;
    });
    builder.addCase(getProjectsCodes.fulfilled, (state, action) => {
      const data = action.payload.data;
      // console.log("Data posted in getOtherRooms.fulfilled:", data);
      state.projectsCodes = data.projects_codes;
      state.projectsCodesLoading = false;
    });
    builder.addCase(getProjectsCodes.rejected, state => {
      state.projectsCodesLoading = false;
    });

    // updateProjectStatus
    builder.addCase(updateProjectStatus.fulfilled, (state, action) => {
      const data = action.payload.data;
      const project = { ...state.activeProject, status: data.status };
      state.activeProject = project;
    });

    //getProjectById
    builder.addCase(getProjectById.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeProject = data;

      // resetTableData
      state.tableData = [];
      // resetTableDataPagination
      state.tableDataPagination = null;
      // resetTableDataFilters
      state.tableDataFilters = {
        perPage: 10,
        page: 1,
        search: '',
        name: '',
      };
    });

    //updateProject
    builder.addCase(updateProject.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateProject.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(updateProject.rejected, state => {
      state.loading = false;
    });

    //getBuildingUnitTypes
    builder.addCase(getBuildingUnitTypes.pending, state => {
      state.buildingUnitTypesLoading = true;
    });
    builder.addCase(getBuildingUnitTypes.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.buildingUnitTypes = data.categories;
      state.buildingUnitTypesLoading = false;
    });
    builder.addCase(getBuildingUnitTypes.rejected, state => {
      state.buildingUnitTypesLoading = false;
    });

    //getFurnitureTypes
    builder.addCase(getFurnitureTypes.pending, state => {
      state.furnitureTypesLoading = true;
    });
    builder.addCase(getFurnitureTypes.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.furnitureTypes = data.categories;
      state.furnitureTypesLoading = false;
    });
    builder.addCase(getFurnitureTypes.rejected, state => {
      state.furnitureTypesLoading = false;
    });

    //getConcreteTypes
    builder.addCase(getConcreteTypes.pending, state => {
      state.concreteTypesLoading = true;
    });
    builder.addCase(getConcreteTypes.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.concreteTypes = data.categories;
      state.concreteTypesLoading = false;
    });
    builder.addCase(getConcreteTypes.rejected, state => {
      state.concreteTypesLoading = false;
    });

    //getBuildingPurposes
    builder.addCase(getBuildingPurposes.pending, state => {
      state.buildingPurposesLoading = true;
    });
    builder.addCase(getBuildingPurposes.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.buildingPurposes = data.categories;
      state.buildingPurposesLoading = false;
    });
    builder.addCase(getBuildingPurposes.rejected, state => {
      state.buildingPurposesLoading = false;
    });

    //getOtherRooms
    builder.addCase(getOtherRooms.pending, state => {
      state.otherRoomsLoading = true;
    });
    builder.addCase(getOtherRooms.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.otherRooms = data.categories;
      state.otherRoomsLoading = false;
    });
    builder.addCase(getOtherRooms.rejected, state => {
      state.otherRoomsLoading = false;
    });
  },
});

export const { setProject, setUnits, clearCache } = projectsSlice.actions;

export default projectsSlice.reducer;
