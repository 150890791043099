import styled from 'styled-components';
import { device } from 'constants';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  @media ${device.isSmallDevice} {
    flex-direction: column;
    gap: 2rem;
  }

  .items {
    display: flex;
    width: 59%;
    gap: 0.5rem;

    @media ${device.isSmallDevice} {
      width: 100%;
    }

    @media (max-width: 600px) {
      overflow-x: auto;
      padding-block: 0.8rem
    }
  }

  .item {
    width: 13%;
    background-color: transparent;
    min-height: 5.56rem;
    box-shadow: 2px 4px 8px 1px #eeeeee;
    border-radius: 1.2rem;
    padding-inline: 1rem;
    display: flex;
    gap: 0.4rem;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    width: 25%;

    @media ${device.isSmallDevice} {
      min-width: 10rem;
      width: 20%;
    }


    svg {
      width: 1.23rem;
      height: 1.23rem;

      path {
        fill: variables.$primaryColor;
      }
    }
  }

  .row-wrapper {
    display: flex;
    width: 35%;
    box-shadow: 2px 4px 8px 4px #eeeeee;
    border-radius: 1.2rem;

    @media ${device.isSmallDevice} {
      width: 70%;
      min-width: 21rem;
    }
  }

  .data-rows {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    flex-direction: column;

    .data-row {
      width: 100%;
      padding: 0.3rem;
      display: flex;
      gap: 0.8rem;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #eee;

      p {
        color: ${props => props.theme.textBlack};
      }
    }
  }
`;
