import { addAuthorizationHeader, api } from './axios';
import { queryBuilder } from 'utils';

export const getAllSuppliers = ({ query }) => {
  return api.get(
    `/suppliers${queryBuilder(query, ['page', 'search', 'name'])}`,
    addAuthorizationHeader(),
  );
};

export const getSupplierById = id => {
  return api.get(`/get-supplier-by-id/${id}`, addAuthorizationHeader());
};

export const createSupplier = data => {
  return api.post('/create-supplier', data, addAuthorizationHeader());
};

export const updateSupplier = (id, data) => {
  return api.put(`update-supplier/${id}`, data, addAuthorizationHeader());
};
