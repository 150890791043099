import styled from "styled-components";
import { device } from "constants";

export const Wrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 2px 4px 8px 1px #eeeeee;
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${device.isSmallDevice} {
    overflow: hidden;
  }
  img {
    width: 100%;
  }

  svg {
    width: 0.8rem;
    height: 0.8rem;

    path {
      fill: ${(props) => props.theme.primaryColor};
    }
  }

  & > .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }

  .graph-header-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }
`;
