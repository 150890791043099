import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${props => props.theme.greyText};
    font-size: 1.1428rem;
    font-weight: 600;
    line-height: 1.4285rem;
    letter-spacing: 0.0057rem;
  }

  nav.MuiPagination-root {
    li {
      &:last-of-type {
        button {
          margin-right: 0;
        }
      }

      button {
        border-radius: 0.2142rem;
        border: 0.0714rem solid #5a5a5a80;
        background: ${props => props.theme.white};
        color: ${props => props.theme.primaryColor};
        font-size: 1.2857rem;
        padding: 0.8571rem 1.1428rem;

        &.Mui-selected {
          color: ${props => props.theme.white};
          background: ${props => props.theme.primaryColor};
          border-color: ${props => props.theme.primaryColor};
        }
      }
    }
  }
`;
