import { BellIcon, BurgerIcon } from 'assets/svg';
import React, { useEffect } from 'react';
import { avatar } from 'assets';
import { Wrapper, Avatar, User, searchbarHeight } from './TopNav.style';
import { Input } from 'components';
import { SearchIcon } from 'assets/svg';
import { getProfile } from 'store/user/actions';
import { useDispatch, useSelector } from 'react-redux';

export default function TopNav({ isSmallDevice, setIsSideNavOpen }) {
  const { profile } = useSelector(({ user }) => user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const displayName = () => {
    const first_name = profile?.first_name || '';
    const last_name = profile?.last_name || '';

    return `${first_name} ${last_name}`;
  };

  return (
    <Wrapper>
      <div className="nav-content">
        {isSmallDevice && (
          <BurgerIcon
            className="burger"
            onClick={() => {
              setIsSideNavOpen(prevState => !prevState);
            }}
          />
        )}
        {/* <Input
          startDecorator={<SearchIcon />}
          width={'21.42857rem'}
          height={searchbarHeight}
          className="searchbar"
        /> */}
      </div>

      <div className="nav-content">
        {/* 
        <div className="plus-badge">
          <PlusIcon />
        </div> 
        <div className="divider"></div>
      
        */}
        <BellIcon className="cursor-pointer" />

        <div className="divider"></div>

        <User>
          <span>
            <Avatar>
              <img src={avatar} alt="avatar" />
            </Avatar>
          </span>
          <div className="user-details">
            <span className="name ellipsis">{displayName()}</span>
            <span className="role ellipsis">Super Admin</span>
          </div>
        </User>
      </div>
    </Wrapper>
  );
}
