import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateActiveTabIndex } from 'store/tab/reducer';
import { checkActiveLink } from 'utils';

const useGotoTab = (tabId, config) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const match = config.find(item =>
      item?.url ? checkActiveLink(location, item.url, true) : false,
    );

    if (match) {
      dispatch(updateActiveTabIndex({ tabIndex: match.offset, tabId }));
    } else {
      if (config[0]?.url) {
        navigate(config[0].url);
      }
    }
  }, [dispatch, tabId, location]);
};

export default useGotoTab;
