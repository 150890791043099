import styled from 'styled-components';
import SimpleModal from 'simple-react-modal';

export const Modal = styled(SimpleModal)`
    &.modal-wrapper {
        position: fixed;
        inset: 0;
        z-index: 11;
        transition: opacity 1s ease-in 0s;
        background: #01050cb2;
        backdrop-filter: blur(0.0714rem);
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-container {
            position: relative;
            min-width: 28.0714rem;
            max-width: 80%;
            background: white;
            border-radius: 6px;
            z-index: 40;

            .close-icon {
              height 16px;
              width 16px;
              cursor: pointer;
              position: absolute;
              top: 16px;
              right: 16px;
            
              path {
                stroke: ${props => props.theme.greyText};
              }

              &:hover {                  
                path {
                  stroke: ${props => props.theme.primaryColor};
                }
              }
            }

            .content {
              .check-icon, 
              .cancel-icon {
                display: block;
                margin: 5.71428rem auto 1.7142rem auto;
              }
              
              h6 {
                margin: 0 16px;
                overflow-wrap: break-word;
                text-align: center;
                margin-bottom: 5.71428rem;
              }
            }
        }
    }
`;
