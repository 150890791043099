import { device } from "constants";
import styled from "styled-components";

export const Wrapper = styled.nav`
  position: fixed;
  width: ${(props) => props.theme.sideBarWidth};
  background-color: ${(props) => props.theme.primaryColor};
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50;
  padding: 0;
  padding-left: 0.8rem;

  &.close {
    display: none;
  }

  .logo-wrapper {
    background-color: ${(props) => props.theme.primaryColor};
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-block: 0.8rem;
  }

  .logo {
    width: 6rem;
    height: 4rem;
    display: flex;
    align-self: center;
    margin: auto;
  }

  @media ${device.laptop} {
    width: 11.8rem;
  }
`;

export const NavItems = styled.ul`
  margin-top: 1rem;
  padding: 0;
  width: 90%;

  a {
    text-decoration: none;
    color: inherit;
  }

  li,
  .link-dropdown {
    display: flex;
    font-style: normal;
    font-weight: 700;
    padding-inline: 0.5rem;
    margin-bottom: 0.9rem;
    border-radius: 0.6rem;

  }

  .link-dropdown {
    margin-bottom: 0;
  }
`;

export const Dropdown = styled.div`
  margin-bottom: 0.9rem;

  .dropdown-flex {
    margin-top: 0.8rem;
    background: white;
    border-radius: 0.6rem;

    

    li {
      color: ${props => props.theme.textBlack};
      padding-block: 0.5rem;
      margin: 0;

      &.active {
        background-color: rgba(255, 105, 0, 0.1);
        color: ${(props) => props.theme.primaryColor};
      }
    }
  }

  .dropdown-arrow {
    width: 0.5rem;
    height: 0.5rem;

    path {
      fill: white;
    }
  }
`;

export const Logout = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
  font-weight: 700;
  font-size: 0.7rem;
  align-items: center;
  padding: 1rem 0.8rem;
  cursor: pointer;

  background-color: ${(props) => props.theme.primaryColor};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (max-width: 991px) {
    margin-bottom: 0.8rem;
  }

  svg {
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
  }

  path {
    fill: rgba(255, 255, 255, 0.7);
  }
`;
