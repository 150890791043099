import { createSlice } from '@reduxjs/toolkit';
import {
  getSupplyRequest,
  reviewSupplyRequest,
  updateSupplyRequest,
  getSupplyRequestHistory,
} from './actions';

const initialState = {
  activeSupplyRequest: null,
  activeSupplyRequestHistory: null,
};

const supplyRequestSlice = createSlice({
  name: 'supplyRequest',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getSupplyRequest
    builder.addCase(getSupplyRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeSupplyRequest = data;
    });

    // reviewSupplyRequest
    builder.addCase(reviewSupplyRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeSupplyRequest = data;
    });

    // updateSupplyRequest
    builder.addCase(updateSupplyRequest.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeSupplyRequest = data;
    });

    // getSupplyRequestHistory
    builder.addCase(getSupplyRequestHistory.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeSupplyRequestHistory = data;
    });
  },
});

export default supplyRequestSlice.reducer;
